<script setup lang="ts">
import type { ComputeSpendCoverageBreakdown} from '@console/services/gcp/api.models';
import type { PropType } from 'vue';

import _ from 'lodash';
import { computed } from 'vue';

import { otherSavingsCategories } from '@console/services/gcp/api.models';
import NumberHelpers from '@shared/utilities/number_helpers';

import SectionChart, { type Section, type Thumb } from '@console/components/charts/SectionChart.vue';
import Currency from '@shared/components/Currency.vue';
import Percentage from '@shared/components/Percentage.vue';
import PanelSection from "@shared/design/panels/PanelSection.vue";
import Tooltip from '@shared/design/Tooltip.vue';

const props = defineProps({
  spendCoverage: {
    type: Object as PropType<ComputeSpendCoverageBreakdown>,
    required: true,
  },
  finalized: {
    type: Boolean,
    required: true,
  },
});

interface SpendCoverage {
  name: string;
  field: 'unbilled' | 'base' | 'inherited' | 'smart' | 'other';
  color: string;
}

const computeSpendCoverages: SpendCoverage[] = [
  { name: 'Unbilled', field: 'unbilled', color: '#05004d' },
  { name: 'Base', field: 'base', color: '#d4d1ff' },
  { name: 'Inherited', field: 'inherited', color: '#5c54ff' },
  { name: 'Smart', field: 'smart', color: '#a7a3ff' }
];

const spendCoverages = computed(() =>
    computeSpendCoverages.map(s => ({
        name: s.name,
        label: `${s.name} Spend Coverage`,
        color: s.color,
        amount: props.spendCoverage[`${s.field}_spend_coverage`] ?? 0,
        percent: props.spendCoverage[`${s.field}_spend_coverage_percentage`] ?? 0,
      }))
      // Exclude any that don't have any amount
      .filter(s => s.amount > 0)
);

const otherCoverages = computed(() =>
  otherSavingsCategories.map(oc => ({
    name: oc.name,
    label: `${oc.name} Spend Coverage`,
    amount: props.spendCoverage[`${oc.field}_coverage`] ?? 0,
    percent: props.spendCoverage[`${oc.field}_coverage_percentage`] ?? 0,
  }))
      // Exclude any that don't have any amount
      .filter(s => s.amount > 0)
);

const sections = computed<Section[]>(() => spendCoverages.value
  .map(sc => ({
    key: sc.label,
    color: sc.color,
    value: sc.percent,
  } as Section))
  .concat([{
    key: 'On-Demand',
    color: '#f5f6fa',
    value: _.round(100 - (props.spendCoverage.overall_spend_coverage_percentage ?? 0), 2),
    showTooltip: false,
  }])
  .filter(s => !!s.value)
);

const total = computed(() => {
  if (props.spendCoverage.overall_spend_coverage && props.spendCoverage.overall_spend_coverage_percentage) {
    return (props.spendCoverage.overall_spend_coverage / props.spendCoverage.overall_spend_coverage_percentage) * 100;
  }
  return undefined;
});

const thumb = computed<Thumb>(() => {
  const target = props.spendCoverage.base_target ?? 0;
  const position = !total.value ? 0 : (target / total.value) * 100;
  return {
    visible: !!total.value && props.finalized,
    position: position,
    description: `Base Target: ${NumberHelpers.formatDollars(target, 0)}`,
  };
});

const labelClasses = (name: string) => {
    if (name === 'Other' && otherCoverages.value.length > 0) {
      return 'tooltip-target';
    }
    return null;
};
</script>

<template>
  <PanelSection header="Spend Coverage" class="spendCoverageBreakdownPanel">
    <div class="spendCoverageBreakdown">
      <div class="d-flex justify-content-center pt-5">
        <SectionChart :sections="sections" :thumb="thumb" />
      </div>
      <div class="tableWrapper">
        <table class="table mt-5">
          <tr v-for="sc in spendCoverages" :key="sc.label">
            <th>
              <div class="base usageType" :style="`background-color: ${sc.color}`"></div>
              <span :id="`${sc.name}_label`" :class="labelClasses(sc.name)">
                {{ sc.label }}
              </span>
              <Tooltip
                v-if="sc.name === 'Other' && otherCoverages.length > 0"
                :target="`${sc.name}_label`"
                placement="bottom"
              >
                <div v-for="other in otherCoverages" :key="other.name" class="tooltipSpendCoverage">
                  {{ other.label }}: <Currency :value="other.amount" /> (<Percentage :value="other.percent" />)
                </div>
              </Tooltip>
            </th>
            <td>
              <Currency :value="sc.amount" />
            </td>
            <td>
              <Percentage :value="sc.percent" />
            </td>
          </tr>
          <tr class="footer">
            <th>Overall Spend Coverage</th>
            <td>
              <Currency :value="spendCoverage.overall_spend_coverage" />
            </td>
            <td>
              <Percentage :value="spendCoverage.overall_spend_coverage_percentage" />
            </td>
          </tr>
        </table>
      </div>
      <div class="d-flex flex-row-reverse">
        <small>(normalized)</small>
      </div>
    </div>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.spendCoverageBreakdownPanel {
  height: auto;
}

.spendCoverageBreakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.spendCoverageBreakdown > div:nth-child(1),
.spendCoverageBreakdown > div:nth-child(2) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

.tableWrapper {
  flex-grow: 1;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
  th,
  td {
    border: 0;
  }
  .footer {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}

.tableWrapper tr td {
  padding-right: 0;
  text-align: right;
}

.tableWrapper tr th {
  padding-left: 0;
}

.tableWrapper tr th,
.tableWrapper tr td {
  // Default the padding and font-with
  padding-top: 1rem;
  font-weight: 400;
}

.tableWrapper tr:nth-last-child(2) th,
.tableWrapper tr:nth-last-child(2) td {
  // Increase the bottom padding for the second to last row (before the footer)
  padding-bottom: 1.5rem;
}

.tableWrapper tr:last-child th,
.tableWrapper tr:last-child td {
  padding-top: 1.5rem;

  // Bold the text and increase the top padding for the footer row
  font-weight: 600;
}

.usageType {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-bottom: 1px;
  border-radius: 50%;
}

.tooltipSpendCoverage {
  font-size: 12px;
}
</style>
