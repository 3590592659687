<script setup lang="ts">
import type {
  CommitedUseDiscountExpirationTimeline,
  CommitedUseDiscountExpiration,
} from '@console/services/gcp/api.models';
import type { XAxisOptions, YAxisOptions, AxisLabelsFormatterContextObject } from 'highcharts';

import moment from 'moment';
import { computed, onMounted, ref } from 'vue';

import * as chartUtility from '@console/components/charts/utility';
import * as mask from '@console/lib/mask';
import { useVuexStore } from '@console/state/vuex/store';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegendV2.vue';
import ScatterChart from '@console/components/charts/ScatterChart.vue';
import TimelineCircle from '@shared/design/icons/TimelineCircle.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

interface LegendElement {
  label: string;
  color: string;
  selected: boolean;
  hasData: boolean;
}

interface SeriesElement {
  name: string;
  color: string;
  data: object[];
}

const planTermPriority = new Map<string, number>([
  ['TWELVE_MONTH', 1],
  ['THIRTY_SIX_MONTH', 2],
]);

const colors = {
  TWELVE_MONTH: '#a7a3ff',
  THIRTY_SIX_MONTH: '#fc5454',
};

const labels = {
  TWELVE_MONTH: '1 Year CUD',
  THIRTY_SIX_MONTH: '3 Year CUD',
};

const fillColors = {
  true: '#ffffff',
  false: null,
};

const lineWidths = {
  true: 3,
  false: null,
};

const props = defineProps<{
  timeline: CommitedUseDiscountExpirationTimeline;
  dataThroughDate: string;
}>();

const legend = ref<LegendElement[]>([]);
const series = ref<SeriesElement[]>([]);
const store = useVuexStore();
const isDemo = computed(() => store.getters['customer/isDemo']);

const projectId = computed(() => {
  return isDemo.value ? mask.redact(props.timeline.project_id) : props.timeline.project_id;
});

const planTerms = computed(() => {
  // extract the unique plan terms among the expiration points
  const plans = props.timeline.expirations.map(e => e.plan);
  return [...new Set(plans)];
});

const height = computed(() => {
  return planTerms.value.length * 50;
});

const tooltip = computed(() => {
  return {
    enabled: true,
    borderRadius: 0,
    borderColor: '#adb5bd',
    shared: true,
    shadow: false,
    headerFormat: '',
    pointFormatter: function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      var point = this as any;
      const vCpuTip =
        point?.po_hourly_price_v_cpu > 0 ? `vCPU Commitment: <b>${point?.po_hourly_price_v_cpu}/hr</b><br>` : '';

      const gbRamTip =
        point?.po_hourly_price_gb_ram > 0 ? `GB RAM Commitment: <b>${point?.po_hourly_price_gb_ram}/hr</b><br>` : '';

      return `Expiration: <b>${point?.po_date}</b><br>${vCpuTip} ${gbRamTip}Percent of Match Key CUD commit: <b>${point?.po_percentage_of_total_match_key_commitment}%</b><br>`;
    },
  };
});

const filteredSeries = computed(() => {
  const labels = legend.value.filter(l => l.selected).map(s => s.label);
  return series.value.map(s => ({ ...s, visible: labels.includes(s.name) }));
});

const xAxis = computed<XAxisOptions>(() => {
  const startDate = moment.utc(props.dataThroughDate).startOf('month').toDate();
  const endDate = moment.utc(props.dataThroughDate).endOf('month').add(3, 'years').toDate();
  return {
    type: 'datetime',
    min: +startDate,
    max: +endDate,
    tickLength: 0,
    labels: {
      formatter: function (ctx) {
        // Add needed property to type defintion,
        // Highcharts is missing this in type definition but it is in the object
        const context = ctx as AxisLabelsFormatterContextObject & { tickPositionInfo?: { unitName?: string } };

        const formats = { day: 'MMM DD, YYYY', week: 'MMM DD, YYYY' };
        const unit = context?.tickPositionInfo?.unitName ?? 'unknown';
        return moment.utc(ctx.value).format(formats[unit as keyof typeof formats] ?? 'MMM YYYY');
      },
    },
    plotLines: chartUtility.monthlyPlotLines(startDate, endDate),
  };
});

const yAxis = computed<YAxisOptions[]>(() => {
  return [
    {
      visible: true,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      categories: planTerms.value,
      min: 1,
      max: planTerms.value.length,
    },
  ];
});

const legendElement = (key: string): LegendElement => {
  return {
    label: labels[key as keyof typeof labels],
    color: colors[key as keyof typeof colors],
    selected: true,
    hasData: true,
  };
};

const seriesElement = (plan: string, index: number) => {
  const expirations = props.timeline.expirations.filter(e => e.plan === plan);
  const color = colors[plan as keyof typeof colors];
  const label = labels[plan as keyof typeof labels];
  const toData = (expiration: CommitedUseDiscountExpiration) => {
    const endDate = moment.utc(expiration.end_date);
    const hourlyVCpu = expiration.hourly_price_v_cpu;
    const hourlyGbRam = expiration.hourly_price_gb_ram;
    const percentage = expiration.percentage_of_total_match_key_commitment;
    const seed = expiration.is_seed;

    return {
      x: +endDate,
      y: index + 1,
      po_date: endDate.format('MMMM DD, YYYY'),
      po_hourly_price_v_cpu: NumberHelpers.formatNumber(hourlyVCpu, 4),
      po_hourly_price_gb_ram: NumberHelpers.formatNumber(hourlyGbRam, 4),
      po_percentage_of_total_match_key_commitment: NumberHelpers.formatNumber(percentage, 3),
      marker: {
        radius: 5 + percentage / 10,
        symbol: 'circle',
        fillColor: fillColors[`${seed}`],
        lineWidth: lineWidths[`${seed}`],
        lineColor: color,
      },
    };
  };
  return {
    name: label,
    color: color,
    data: expirations.map(e => toData(e)),
  };
};

onMounted(async () => {
  var orderedPlanTerms = planTerms.value.sort((a, b) => {
    return (planTermPriority.get(a) ?? 0) - (planTermPriority.get(b) ?? 0);
  });

  series.value = orderedPlanTerms.map((p, idx) => seriesElement(p, idx));
  legend.value = orderedPlanTerms.map(p => legendElement(p));
});
</script>

<template>
  <PanelSection :header="`${timeline.match_key} - ${projectId}`">
    <template v-slot:header>
      <div class="flex-grow-1">
        {{ timeline.friendly_name }}<br />
        <small class="text-muted">{{ timeline.match_key }}</small>
      </div>
    </template>
    <template v-slot:utility>
      <div class="text-right">
        {{ projectId }}
      </div>
    </template>
    <div class="cudExpirationTimeline">
      <ChartLegend v-model:model-value="legend" />
      <ScatterChart
        :x-axis="xAxis"
        :y-axis="yAxis"
        :tooltip="tooltip"
        :series="filteredSeries"
        :height="height"
        zoomable
      />
      <div class="d-flex pt-2 pl-2">
        <div class="pr-4">
          <TimelineCircle class="text-muted mr-1" />
          <small class="text-muted">Commitment</small>
        </div>
        <div>
          <TimelineCircle class="text-muted mr-1" hollow />
          <small class="text-muted">Seed</small>
        </div>
      </div>
    </div>
  </PanelSection>
</template>

<style scoped>
.cudExpirationTimeline + .cudExpirationTimeline {
  margin-top: 1.5rem;
}
</style>
