<script setup lang="ts">
import type { GetAuthConfigResponse } from '@shared/services/customer.models';
import type { User } from '@shared/state/users.store';

import { useHead } from '@unhead/vue';
import Clipboard from 'clipboard';
import _ from 'lodash';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

import { useVuexStore } from '@console/state/vuex/store';
import { getAuthConfig, resendVerification } from '@shared/services/customer';
import { AuthTypes } from '@shared/services/customer.models';
import { useUsersStore } from '@shared/state/users.store';
import env from '@shared/utilities/environment';

import FlashSuccess from '@console/components/FlashSuccess.vue';
import UserList from '@console/components/users/UserList.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import TextInput from '@shared/design/TextInput.vue';
import Tooltip from '@shared/design/Tooltip.vue';

const userStore = useUsersStore();
const vuexStore = useVuexStore();
const router = useRouter();

interface Props {
  flashMessage: string | null;
}
const props = defineProps<Props>();

useHead({
  title: 'User Management',
});

const authConfig = ref<GetAuthConfigResponse | null>(null);
const filter = ref(typeof router.currentRoute.value.query.q === 'string' ? router.currentRoute.value.query.q : '');
const currentFlashMessage = ref(props.flashMessage);
const clipboard = ref(new Clipboard('.copyUserLoginUrl'));
const isLoading = ref(false);

const isFederated = computed(() => {
  const company = vuexStore.getters['customer/selectedCompany'];
  return company.auth_type === AuthTypes.Federated;
});

const federationProtocol = computed(() => {
  if (!isFederated.value) {
    return null;
  }
  if (authConfig.value?.strategy === 'oidc') {
    return 'OpenID Connect (OIDC)';
  }
  if (authConfig.value?.strategy === 'samlp') {
    return 'Security Assertion Markup Language (SAML)';
  }
  return null;
});

const federationDomainLabel = computed(() => {
  const domains = _.get(authConfig.value, 'domain_aliases', []);
  if (domains.length === 1) {
    return 'Domain';
  }
  return 'Domains';
});

const federationDomains = computed(() => {
  const domains = _.get(authConfig.value, 'domain_aliases', []);
  if (domains.length === 0) {
    return 'Not Set';
  }
  return domains.map(d => `@${d}`).join(', ');
});

const loginUrl = computed(() => {
  const customerId = vuexStore.state.customer.selectedCompanyId;
  const consoleUrl = env.get('VITE_BASE_URL');
  return `${consoleUrl}/federated_login/${customerId}`;
});

const filtered = computed(() => {
  if (!filter.value) {
    return userStore.users;
  }
  return _.filter(userStore.users, u => _.includes(u.searchIndex, filter.value.toLowerCase()));
});

async function loadAuthConfig() {
  const response = await getAuthConfig();
  authConfig.value = response.data;
}

async function onResendVerification(user: User) {
  await resendVerification(user.email_address);
  showFlashMessage('Verification email sent!');
}

function showFlashMessage(message: string) {
  currentFlashMessage.value = message;
  setTimeout(() => {
    currentFlashMessage.value = null;
  }, 5000);
}

onMounted(async () => {
  isLoading.value = true;
  try {
    if (isFederated.value) {
      await loadAuthConfig();
    } else if (userStore.users.length === 0) {
      // don't need to load every time we mount to help keep things snappy
      await userStore.refresh();
    }

    if (props.flashMessage) {
      showFlashMessage(props.flashMessage);
    }
  } catch (error) {
    await router.push({ name: 'error' });
    throw error;
  }
  isLoading.value = false;
});

onUnmounted(() => {
  clipboard.value.destroy();
});
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <FlashSuccess v-if="currentFlashMessage" :message="currentFlashMessage" />
      <PageHeader>
        <h1>User Management</h1>
      </PageHeader>
      <div v-if="isFederated" class="pt-3">
        <BoxMessage type="info" class="pb-4">
          <p class="mb-0">
            All users from your company login to the ProsperOps Console using identity federation. To make a change to
            any of the settings below, please <router-link :to="{ name: 'help' }">contact us</router-link>.
          </p>
        </BoxMessage>
        <div class="row">
          <div class="col">
            <PanelSection header="Settings">
              <div v-if="authConfig?.strategy === 'samlp'" class="setting">
                <div>Sign In Endpoint</div>
                <div>
                  <div class="settingValue">
                    {{ authConfig.sign_in_endpoint }}
                  </div>
                </div>
              </div>
              <div v-if="authConfig?.strategy === 'oidc'" class="setting">
                <div>Issuer</div>
                <div>
                  <div class="settingValue">
                    {{ authConfig.issuer }}
                  </div>
                </div>
              </div>
              <div class="setting">
                <div>Protocol</div>
                <div>
                  <div class="settingValue">
                    {{ federationProtocol }}
                  </div>
                </div>
              </div>
              <div class="setting">
                <div>{{ federationDomainLabel }}</div>
                <div>
                  <div class="settingValue">
                    {{ federationDomains }}
                  </div>
                </div>
              </div>
              <div class="setting">
                <div>
                  <span id="distributionList" class="tooltip-target"> Email Distribution List </span>
                </div>
                <Tooltip target="distributionList" placement="bottom">
                  All emails, excluding billing emails, will be sent to this email address.
                </Tooltip>
                <div>
                  <div class="settingValue">
                    <span v-if="authConfig?.distribution_list">
                      {{ authConfig.distribution_list }}
                    </span>
                    <span v-else> (Not Set) </span>
                  </div>
                </div>
              </div>
              <div class="setting">
                <div>
                  <span id="loginUrl" class="tooltip-target"> Login URL </span>
                </div>
                <Tooltip target="loginUrl" placement="bottom">
                  Users can optionally visit this URL directly to login without needing to enter their email address on
                  our login screen. Certain identity providers, such as Okta, support configuring this URL as a bookmark
                  app for your users to login.
                </Tooltip>
                <div>
                  <div class="settingValue">
                    {{ loginUrl }}
                    <b-button
                      id="loginUrlCopy"
                      class="mb-1 pl-1 pr-0 copyUserLoginUrl"
                      :data-clipboard-text="loginUrl"
                      size="sm"
                      variant="link"
                    >
                      <BaseIcon name="copy" variant="far" />
                    </b-button>
                    <Tooltip target="loginUrlCopy"> Click to copy Login URL </Tooltip>
                  </div>
                </div>
              </div>
            </PanelSection>
          </div>
        </div>
      </div>
      <div v-else class="pt-3">
        <div class="row">
          <div class="col d-flex">
            <TextInput id="filter" v-model="filter" type="search" class="flex-grow-1" placeholder="Search users" />
            <div class="buttons">
              <b-button variant="primary" :to="{ name: 'add_user' }" class="rounded-sm">
                <BaseIcon name="plus" class="mr-1" />
                Add User
              </b-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div v-if="filtered.length > 0" class="resultState">
              <UserList
                :users="filtered"
                @user-deleted="(user: User) => userStore.delete(user)"
                @resend-verification="onResendVerification"
              />
            </div>
            <div v-else class="emptyState">
              <p class="text-center mb-0">
                {{ filter ? `No users matching "${filter}"` : 'No matched users' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  margin-top: 8px;
  font-size: 1.2rem;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1.25rem;
  white-space: nowrap;
}

.setting {
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.setting.offset {
  padding-top: 0.25rem;
  padding-left: 0;

  @include media-breakpoint-up(md) {
    padding-left: 50px;
  }
}

.setting > div:first-child {
  flex-basis: 0;
  flex-shrink: 0;

  @include media-breakpoint-up(md) {
    flex-basis: 260px;
  }
}

.setting.offset > div:first-child {
  @include media-breakpoint-up(md) {
    flex-basis: calc(260px - 50px);
  }
}

.settingValue {
  display: inline-block;
  font-weight: bold;
}
</style>
