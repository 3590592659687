<script>
import Big from 'big.js';
import _ from 'lodash';

import Currency from '@shared/components/Currency.vue';

export default {
  components: {
    Currency,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasTotalAllocation() {
      return (
        this.isBaseTargetSet(this.settings.base_one_year_target_hourly_on_demand_equivalent) ||
        this.isBaseTargetSet(this.settings.base_three_year_target_hourly_on_demand_equivalent)
      );
    },
    totalAllocation() {
      return _.sum([
        this.convertHourlyToMonthly(this.settings.base_one_year_target_hourly_on_demand_equivalent),
        this.convertHourlyToMonthly(this.settings.base_three_year_target_hourly_on_demand_equivalent),
      ]);
    },
  },
  methods: {
    convertHourlyToMonthly(value) {
      return Number(Big(value).times(720));
    },
    isNumber(n) {
      return _.isNumber(n);
    },
    isBaseTargetSet(n) {
      return _.isNumber(n) && n > 0;
    },
  },
};
</script>

<template>
  <div>
    <div>
      <span v-if="hasTotalAllocation">
        <Currency :value="totalAllocation" :decimals="0" zero="-" class="font-weight-bold" />
        <span v-if="totalAllocation > 0"> per month</span>
      </span>
      <span v-else> - </span>
    </div>
    <div v-if="hasTotalAllocation">
      <div class="pl-3 pt-2">
        <span class="mr-1">1 Year: </span>
        <Currency
          :value="convertHourlyToMonthly(settings.base_one_year_target_hourly_on_demand_equivalent)"
          :decimals="0"
          zero="-"
          class="font-weight-bold"
        />
      </div>
      <div class="pl-3 pt-2">
        <span class="mr-1">3 Year: </span>
        <Currency
          :value="convertHourlyToMonthly(settings.base_three_year_target_hourly_on_demand_equivalent)"
          :decimals="0"
          zero="-"
          class="font-weight-bold"
        />
      </div>
    </div>
  </div>
</template>
