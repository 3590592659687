import { helpers } from '@vuelidate/validators';

const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

// Vuelidate rule: 'validGuid'
export const validGuid = helpers.withMessage(
  `Must be valid unique identifier (UUID) like '12345678-1234-1234-1234-1234567890ab'`,
  testGuid
);

export function testGuid(value: string) {
  return guidPattern.test(value);
}
