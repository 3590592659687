<script setup lang="ts">
import type { ChartLegendOptions } from '@console/components/charts/ChartLegendV2.vue';
import type { AwsOrganizationComputeWeightedAverageDurationTrend } from '@console/services/api.models';
import type { SeriesAreaOptions, SeriesLineOptions } from 'highcharts';

import { computed, ref, onMounted } from 'vue';

import * as chartUtilities from '@console/components/charts/utility';

import ChartLegend from '@console/components/charts/ChartLegendV2.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const MS_IN_DAY = 86400000;

type SeriesKey = keyof AwsOrganizationComputeWeightedAverageDurationTrend;
type TrendDataPoint = number | null;

const props = defineProps<{
  trend: AwsOrganizationComputeWeightedAverageDurationTrend;
  dataThroughDate: string;
}>();

type SeriesDataPoint = [number, TrendDataPoint];
type SeriesOptions = Omit<SeriesLineOptions | SeriesAreaOptions, 'name' | 'data'> & {
  name: string;
  data: Array<SeriesDataPoint>;
};

const series = ref<Array<SeriesOptions>>([]);
const legend = ref<Array<ChartLegendOptions>>([]);

const colors: Record<SeriesKey, string> = {
  convertible_reserved_instances_days: '#00c58c',
  standard_reserved_instances_days: '#fcbe2c',
  compute_savings_plans_days: '#5c54ff',
  ec2_instance_savings_plans_days: '#8fffdf',
  overall_days: '#adb5bd',
};

const labels: Record<SeriesKey, string> = {
  convertible_reserved_instances_days: 'Convertible RIs',
  standard_reserved_instances_days: 'Standard RIs',
  compute_savings_plans_days: 'Compute SPs',
  ec2_instance_savings_plans_days: 'EC2 Instance SPs',
  overall_days: 'Overall',
};

const tooltip = {
  enabled: true,
  borderRadius: 0,
  borderColor: '#adb5bd',
  shared: true,
  shadow: false,
  xDateFormat: '<span style="font-size: 12px">%B %d, %Y</span>',
  useHTML: true,
  dateTimeLabelFormats: {
    millisecond: '%b %e, %Y',
  },
};

const xAxis = {
  type: 'datetime',
  tickLength: 0,
  labels: {
    formatter: chartUtilities.datetimeZoomLabelFormatter,
  },
};

const yAxis = [
  {
    min: 0,
    title: {
      text: 'Days',
      margin: 20,
    },
    labels: {
      format: '<span style="font-size:12px">{value:,.0f}</span>',
    },
  },
];

const filteredSeries = computed(() => {
  const labels = legend.value.filter(l => l.selected).map(l => l.label);
  return series.value.filter(s => labels.includes(s.name));
});

onMounted(() => {
  const legendElements = [
    legendElement('convertible_reserved_instances_days'),
    legendElement('standard_reserved_instances_days'),
    legendElement('compute_savings_plans_days'),
    legendElement('ec2_instance_savings_plans_days'),
    legendElement('overall_days'),
  ];
  const seriesElements = [
    seriesElement('overall_days'),
    seriesElement('ec2_instance_savings_plans_days'),
    seriesElement('compute_savings_plans_days'),
    seriesElement('standard_reserved_instances_days'),
    seriesElement('convertible_reserved_instances_days'),
  ];
  series.value = seriesElements.filter(el => el.data && el.data.some(d => d[1]));
  legend.value = legendElements.filter(l => l.hasData);
});

const dayCount = computed(() => {
  const propNames: Array<SeriesKey> = [
    'convertible_reserved_instances_days',
    'standard_reserved_instances_days',
    'compute_savings_plans_days',
    'ec2_instance_savings_plans_days',
    'overall_days',
  ];
  const days = propNames.map(prop => props.trend[prop].length);
  return Math.max(...days) - 1;
});

const enrich = (element: TrendDataPoint, index: number): SeriesDataPoint => {
  const dataThroughDate = props.dataThroughDate;
  const msStart = +new Date(dataThroughDate) - MS_IN_DAY * dayCount.value;
  return [msStart + index * MS_IN_DAY, element];
};

const seriesElement = (key: SeriesKey): SeriesOptions => {
  const trend = props.trend;
  const color = colors[key];
  const data = trend[key].map(enrich);
  return {
    name: labels[key],
    type: key === 'overall_days' ? 'area' : 'line',
    data,
    color: color,
    marker: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: ' days',
    },
  };
};

const legendElement = (key: SeriesKey): ChartLegendOptions & { hasData: boolean } => {
  const trend = props.trend;
  const color = colors[key];
  const hasData = trend[key].some(d => d);
  return {
    label: labels[key],
    color: color,
    selected: true,
    hasData,
  };
};
</script>

<template>
  <div>
    <ChartLegend v-model:model-value="legend" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :tooltip="tooltip" :series="filteredSeries" zoomable />
  </div>
</template>
