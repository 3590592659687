<script setup lang="ts">
import useUniqueId from '@shared/composables/useUniqueId';

import Tooltip from './Tooltip.vue';

defineProps<{
  tooltipClass?: string;
}>();

const id = `info-tooltip-${useUniqueId()}`;
</script>

<template>
  <span class="wrapper text-primary">
    <div class="icon">
      <BaseIcon :id="id" name="info-circle" />
    </div>
    <Tooltip :target="id" placement="top" :custom-class="tooltipClass">
      <slot />
    </Tooltip>
  </span>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
}
</style>
