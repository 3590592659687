import type { CreateServicePrincipalResponse } from "@azure/services/servicePrincipal";
import type { OnboardingForm } from "@azure/state";

import { generateExportResourceGroupName, generateExportStorageAccountName } from "@azure/services/billingScope";

export const generate = (form: OnboardingForm, servicePrincipal: CreateServicePrincipalResponse) => {
  const resourceGroupName = generateExportResourceGroupName();
  const storageAccountName = generateExportStorageAccountName(form.storageAccountSubscriptionId);

  /* eslint-disable no-useless-escape */
  return `# Constants
AZ_CLI_CMD="az"
BILLING_PROFILE_READER_ROLE_DEFINITION_ID="40000000-aaaa-bbbb-cccc-100000000002"
ENROLLMENT_READER_ROLE_DEFINITION_ID="24f8edb6-1668-4659-b5e2-40bb5f3a7d7e"
RESOURCE_GROUP_LOCATION="eastus2"

# Input
BILLING_ACCOUNT_ID="${form.billingAccountId}"
BILLING_PROFILE_ID="${form.billingProfileId}"
RESOURCE_GROUP_DISPLAY_NAME="${resourceGroupName}"
SPN_APPLICATION_ID="${form.applicationId}"
SPN_TENANT_ID="${form.tenantId}"
STORAGE_ACCOUNT_NAME="${storageAccountName}"
SUBSCRIPTION_ID="${form.storageAccountSubscriptionId}"

# State
AGREEMENT_TYPE=""
ROOT_MANAGEMENT_GROUP=""
SPN_OBJECT_ID=""

export AZURE_CORE_ONLY_SHOW_ERRORS=true

validate_az_cli () {
  printf "==> Validating Azure CLI installation...\\n"
  if ! command -v $AZ_CLI_CMD &> /dev/null; then
    echo "Command 'az' does not exist. Exiting..."
    exit 1
  fi

  printf "==> Validating Azure CLI auth...\\n"
  az account list

  for extension in costmanagement; do
    printf "==> Installing $extension extension...\\n"
    az extension add --name "$extension"
  done
}

validate_service_principal () {
  printf "==> Validating service principal...\\n\\n"

  SPN_OBJECT_ID=$(az rest --method get --url "https://graph.microsoft.com/v1.0/servicePrincipals(appId='$SPN_APPLICATION_ID')" --query id --output tsv)
}

validate_billing_account () {
  printf "==> Validating billing account...\\n\\n"

  az billing account show --name $BILLING_ACCOUNT_ID

  local account_status=$(az billing account show --name $BILLING_ACCOUNT_ID --query accountStatus --output tsv)
  if [ "$account_status" != "Active" ]; then
    printf "Account status must be Active. Found account status: $account_status."
    exit 1
  fi

  AGREEMENT_TYPE=$(az billing account show --name $BILLING_ACCOUNT_ID --query agreementType --output tsv)

  printf "\\n"
}

validate_billing_profile () {
  printf "==> Validating billing profile...\\n\\n"

  if [ "$AGREEMENT_TYPE" = "EnterpriseAgreement" ]; then
    printf "Skipping billing profile validation for Enterprise Agreement account. Found agreement type: $AGREEMENT_TYPE.\\n"
  else
    az billing profile show --account-name $BILLING_ACCOUNT_ID --name $BILLING_PROFILE_ID
  fi

  printf "\\n"
}

validate_root_management_group () {
  printf "==> Validating root management group...\\n\\n"

  az account management-group list
  ROOT_MANAGEMENT_GROUP=$(az account management-group list --query "[?contains(displayName, 'Tenant Root Group')] | [0].name" --output tsv)

  printf "\\n"
}

validate_subscription () {
  printf "==> Validating billing export subscription...\\n\\n"

  az account show --subscription $SUBSCRIPTION_ID

  local state=$(az account show --subscription $SUBSCRIPTION_ID --query state --output tsv)
  if [ "$state" != "Enabled" ]; then
    printf "Subscription state must be Enabled. Found state: $state."
    exit 1
  fi

  az account set --subscription $SUBSCRIPTION_ID

  printf "\\n"
}

register_resource_providers () {
  for provider in Microsoft.CostManagementExports Microsoft.Storage; do
    printf "==> Registering $provider resource provider...\\n"
    az provider register --namespace "$provider" --wait
  done
}

create_storage_account () {
  printf "==> Creating billing export resource group and storage account...\\n\\n"

  STORAGE_ACCOUNT_NAME="prosperops$(echo $SUBSCRIPTION_ID | cut -c 1-8)"

  az group create --name $RESOURCE_GROUP_DISPLAY_NAME --location $RESOURCE_GROUP_LOCATION
  az storage account create --name $STORAGE_ACCOUNT_NAME --resource-group $RESOURCE_GROUP_DISPLAY_NAME

  printf "\\n"
}

upload_service_principal_certificate () {
  printf "==> Uploading service principal certificate...\\n\\n"

  echo '${servicePrincipal?.certificate}' > certificate.pem

  local encoded_certificate=$(cat certificate.pem | base64)
  local application_id=$(az rest --method get --url "https://graph.microsoft.com/v1.0/applications(appId='$SPN_APPLICATION_ID')" --query id --output tsv)

  az rest --method patch \\
    --url "https://graph.microsoft.com/v1.0/applications/$application_id" \\
    --headers "Content-Type=application/json" \\
    --body "{
      \\"keyCredentials\\": [{
        \\"keyId\\": \\"${crypto.randomUUID()}\\",
        \\"type\\": \\"AsymmetricX509Cert\\",
        \\"usage\\": \\"Verify\\",
        \\"key\\": \\"$encoded_certificate\\"
      }]
    }"
}

assign_service_principal_permissions () {
  printf "==> Assigning service principal permissions...\\n\\n"

  if [ "$AGREEMENT_TYPE" = "EnterpriseAgreement" ]; then
    az rest --method put \\
      --url "https://management.azure.com/providers/Microsoft.Billing/billingAccounts/$BILLING_ACCOUNT_ID/billingRoleAssignments/${crypto.randomUUID()}?api-version=2019-10-01-preview" \\
      --headers "Content-Type=application/json" \\
      --body "{
        \\"properties\\": {
          \\"principalId\\": \\"$SPN_OBJECT_ID\\",
          \\"principalTenantId\\": \\"${form.tenantId}\\",
          \\"roleDefinitionId\\": \\"/providers/Microsoft.Billing/billingAccounts/$BILLING_ACCOUNT_ID/billingRoleDefinitions/$ENROLLMENT_READER_ROLE_DEFINITION_ID\\"
        }
      }"
  else
    az rest --method post \\
      --url https://management.azure.com/providers/Microsoft.Billing/billingAccounts/$BILLING_ACCOUNT_ID/billingProfiles/$BILLING_PROFILE_ID/createBillingRoleAssignment\?api-version\=2024-04-01 \\
      --headers "Content-Type=application/json" \\
      --body "{
        \\"principalId\\": \\"$SPN_OBJECT_ID\\",
        \\"roleDefinitionId\\": \\"$BILLING_PROFILE_READER_ROLE_DEFINITION_ID\\"
      }"
  fi

  az role assignment create --assignee-object-id $SPN_OBJECT_ID --assignee-principal-type ServicePrincipal --role "Billing Reader" --scope "/providers/Microsoft.Management/managementGroups/$ROOT_MANAGEMENT_GROUP"
  az role assignment create --assignee-object-id $SPN_OBJECT_ID --assignee-principal-type ServicePrincipal --role "Owner" --scope "/subscriptions/$SUBSCRIPTION_ID/resourceGroups/$RESOURCE_GROUP_DISPLAY_NAME/providers/Microsoft.Storage/storageAccounts/$STORAGE_ACCOUNT_NAME"

  printf "\\n"
}

main () {
  validate_az_cli
  validate_billing_account
  validate_billing_profile
  validate_subscription
  validate_root_management_group
  validate_service_principal

  register_resource_providers
  create_storage_account

  upload_service_principal_certificate
  assign_service_principal_permissions

  echo 'ProsperOps setup complete!'
}

main
`;
};
