<script setup lang="ts">
import { computed, ref } from 'vue';

import { AgreementType, generateExportResourceGroupName, generateExportStorageAccountName } from '@azure/services/billingScope';
import { validateAccess, AccessValidationStatus, type AccessValidationResponse, PermissionSet } from '@azure/services/servicePrincipal';
import { useAzureStore } from '@azure/state';
import * as savingsAnalysisScript from '@azure/utilities/savingsAnalysisScript';

import ValidationStepperItem from '@gcp/components/onboarding/ValidationStepperItem.vue';
import AccordionList from '@shared/design/AccordionList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import CodePreview from '@shared/design/CodePreview.vue';
import CopyButton from '@shared/design/CopyButtonV2.vue';
import Panel from '@shared/design/panels/Panel.vue';
import Tabs from '@shared/design/Tabs.vue';

type OnboardingMethod =
  'portal' |
  'shell';

type OnboardingMethodTab = {
  key: OnboardingMethod;
  label: string;
}

type ValidationStep =
  'certificate-upload' |
  'billing-account-access' |
  'root-management-group-access' |
  'storage-account-access' |
  'register-resource-providers';

const props = defineProps<{
  prev: () => void;
  next: () => void;
}>();

const store = useAzureStore();
const form = computed(() => store.onboarding.form);
const servicePrincipal = computed(() => store.onboarding.servicePrincipal!);

const blob = new Blob([servicePrincipal.value.certificate], { type: 'application/x-pem-file' });
const blobUrl = URL.createObjectURL(blob);
const activeAccordionKey = ref<ValidationStep>('certificate-upload');
const cliValidationError = ref<string>();
const loading = ref<boolean>(false);

const assignmentId = self.crypto.randomUUID();
const enterpriseAgreementCodeSnippet = `SPN_OBJECT_ID=$(az rest --method get --url "https://graph.microsoft.com/v1.0/servicePrincipals(appId='${form.value.applicationId}')" --query id --output tsv)

az rest --method put \\
  --url "https://management.azure.com/providers/Microsoft.Billing/billingAccounts/${form.value.billingAccountId}/billingRoleAssignments/${assignmentId}?api-version=2019-10-01-preview" \\
  --headers "Content-Type=application/json" \\
  --body "{
    \\"properties\\": {
      \\"principalId\\": \\"$SPN_OBJECT_ID\\",
      \\"principalTenantId\\": \\"${form.value.tenantId}\\",
      \\"roleDefinitionId\\": \\"/providers/Microsoft.Billing/billingAccounts/${form.value.billingAccountId}/billingRoleDefinitions/24f8edb6-1668-4659-b5e2-40bb5f3a7d7e\\"
    }
  }"
`;
const onboardingScript = computed<string>(() => savingsAnalysisScript.generate(form.value, servicePrincipal.value));

var selectedOnboardingMethod= ref<OnboardingMethod>('shell');
var onboardingMethodTabs: OnboardingMethodTab[] = [
  { key: 'shell', label: 'Cloud Shell' },
  { key: 'portal', label: 'Azure Portal' },
];

const validateSavingsAnalysisAccess = async (): Promise<AccessValidationResponse> => {
  return await validateAccess(servicePrincipal.value.id, {
    agreement_type: form.value.agreementType,
    billing_account_id: form.value.billingAccountId,
    billing_profile_id: form.value.billingProfileId,
    storage_account_subscription_id: form.value.storageAccountSubscriptionId,
    storage_account_resource_group_name: generateExportResourceGroupName(),
    storage_account_name: generateExportStorageAccountName(form.value.storageAccountSubscriptionId),
    permission_set: PermissionSet.SavingsAnalysis
  });
};

const validateCertificateUpload = async () => {
  const result: AccessValidationResponse = await validateSavingsAnalysisAccess();
  if (result.service_principal_authenticated.status !== AccessValidationStatus.Validated) {
    throw new Error(result.service_principal_authenticated.message);
  }

  activeAccordionKey.value = 'billing-account-access';
};

const validateBillingScopeAccess = async () => {
  const result: AccessValidationResponse = await validateSavingsAnalysisAccess();
  if (result.billing_scope_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.billing_scope_access.message);
  }

  activeAccordionKey.value = 'root-management-group-access';
};

const validateRootManagementGroupAccess = async () => {
  const result: AccessValidationResponse = await validateSavingsAnalysisAccess();
  if (result.root_management_group_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.root_management_group_access.message);
  }

  activeAccordionKey.value = 'storage-account-access';
};

const validateStorageAccountAccess = async () => {
  const result: AccessValidationResponse = await validateSavingsAnalysisAccess();
  if (result.storage_account_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.storage_account_access.message);
  }

  activeAccordionKey.value = 'register-resource-providers';
};

const validateResourceProviders = async () => {
  const result = await validateSavingsAnalysisAccess();
  if (result.cost_management_exports_namespace_registered.status !== AccessValidationStatus.Validated) {
    throw new Error(result.cost_management_exports_namespace_registered.message);
  }

  await store.createBillingScope();
  props.next();
};

const validateAllAccess = async () => {
  loading.value = true;

  const result = await validateSavingsAnalysisAccess();
  if (result.service_principal_authenticated.status !== AccessValidationStatus.Validated) {
    cliValidationError.value = result.service_principal_authenticated.message;
  } else if (result.billing_scope_access.status !== AccessValidationStatus.Validated) {
    cliValidationError.value = result.billing_scope_access.message;
  } else if (result.root_management_group_access.status !== AccessValidationStatus.Validated) {
    cliValidationError.value = result.root_management_group_access.message;
  } else if (result.storage_account_access.status !== AccessValidationStatus.Validated) {
    cliValidationError.value = result.storage_account_access.message;
  } else if (result.cost_management_exports_namespace_registered.status !== AccessValidationStatus.Validated) {
    cliValidationError.value = result.cost_management_exports_namespace_registered.message;
  }

  await store.createBillingScope();
  props.next();

  loading.value = false;
};
</script>

<template>
  <div class="savingsAnalysisPermissions">
    <div class="row">
      <div class="col">
        <h2>Initial Azure Access</h2>
        <div class="pt-2">
          <BoxMessage type="info">
            ProsperOps access is granted via service principal permissions. Follow the steps below to configure access.
            Please ensure you have the
            <a href="https://help.prosperops.com/azure-onboarding-guide-azure-details#roles-required" target="_blank"
              >required permissions</a
            >.
          </BoxMessage>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col">
        <Tabs
          :items="onboardingMethodTabs"
          :active-key="selectedOnboardingMethod"
          @changed="(key: OnboardingMethod) => selectedOnboardingMethod = key"
        />
      </div>
    </div>
    <div v-if="selectedOnboardingMethod === 'portal'" class="row">
      <div class="col">
        <AccordionList class="bg-white">
          <ValidationStepperItem
            title="Upload ProsperOps Certificate"
            :open="activeAccordionKey === 'certificate-upload'"
            :validate="validateCertificateUpload"
          >
            <template #default>
              <ul>
                <li>
                  Download the
                  <a :href="blobUrl" :download="`prosperops-${servicePrincipal.application_id}.pem`"
                    >ProsperOps certificate</a
                  >
                </li>
                <li>
                  Open
                  <a
                    :href="`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Credentials/appId/${form.applicationId}/isMSAApp~/false`"
                    target="_blank"
                    >Certificates & secrets</a
                  >
                  in the Azure portal
                </li>
                <li>Click <b>Upload certificate</b>, then upload the ProsperOps certificate</li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal certificate has been
                uploaded through the Azure portal. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Grant Billing Scope Access"
            :open="activeAccordionKey === 'billing-account-access'"
            :validate="validateBillingScopeAccess"
          >
            <template #default>
              <ul v-if="form.agreementType === AgreementType.MicrosoftCustomerAgreement">
                <li>
                  Open
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_CostManagement/Menu/~/changescope/openedBy/AzurePortal"
                    target="_blank"
                  >
                    Change scope
                  </a>
                  in the Azure portal
                </li>
                <li>Select the <b>Billing profile</b> corresponding to the ID previously provided</li>
                <li>Click <b>Access control</b> on the left blade, then click <b>Add</b></li>
                <li>Select the <b>Billing profile reader</b> role</li>
                <li>Add the <b>ProsperOps</b> service principal, then click <b>Add</b></li>
              </ul>
              <div v-else>
                Execute the following command from Azure Shell:
                <CodePreview :code-as-text="enterpriseAgreementCodeSnippet" class="onboardingCodeSnippet mt-2" />
              </div>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Billing Profile Reader</b> role on the specified billing profile. If the issue persists, please chat
                with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Grant Root Management Group Access"
            :open="activeAccordionKey === 'root-management-group-access'"
            :validate="validateRootManagementGroupAccess"
          >
            <template #default>
              <ul>
                <li>
                  Open
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_Resources/ManagementGroupBrowseBlade/~/MGBrowse_overview"
                    target="_blank"
                    >Management groups</a
                  >
                  in the Azure portal
                </li>
                <li>Select the root management group (default name: <b>Tenant Root Group</b>)</li>
                <li>
                  Select <b>Access control (IAM)</b> on the left blade, then click <b>Add > Add role assignment</b>
                </li>
                <li>Select the <b>Billing Reader</b> role, then click <b>Next</b></li>
                <li>Add the <b>ProsperOps</b> service principal as a member</li>
                <li>Click <b>Review + assign</b></li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Billing Reader</b> role on the root management group. If the issue persists, please chat with us for
                help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Grant Storage Account Access"
            :open="activeAccordionKey === 'storage-account-access'"
            :validate="validateStorageAccountAccess"
          >
            <template #default>
              <ul>
                <li>
                  Open
                  <a target="_blank" href="https://portal.azure.com/#create/Microsoft.StorageAccount-ARM"
                    >Create a storage account</a
                  >
                  in the Azure portal
                </li>
                <li>Select the <b>Subscription</b> corresponding to the ID previously provided</li>
                <li>
                  Create a new <b>Resource group</b> with the following name:<br />
                  <span class="text-monospace bg-light p-1">{{ generateExportResourceGroupName() }}</span>
                  <CopyButton
                    :key="generateExportResourceGroupName()"
                    class="ml-2"
                    :text="generateExportResourceGroupName()"
                  />
                </li>
                <li>
                  Enter the following <b>Storage account name</b>:<br />
                  <span class="text-monospace bg-light p-1">{{
                    generateExportStorageAccountName(form.storageAccountSubscriptionId)
                  }}</span>
                  <CopyButton
                    :key="generateExportStorageAccountName(form.storageAccountSubscriptionId)"
                    class="ml-2"
                    :text="generateExportStorageAccountName(form.storageAccountSubscriptionId)"
                  />
                </li>
                <li>Click <b>Review + create</b>, then click <b>Create</b></li>
                <li>
                  Open
                  <a
                    target="_blank"
                    :href="`https://portal.azure.com/#resource/subscriptions/${
                      form.storageAccountSubscriptionId
                    }/resourceGroups/${generateExportResourceGroupName()}/providers/Microsoft.Storage/storageAccounts/${generateExportStorageAccountName(
                      form.storageAccountSubscriptionId
                    )}/iamAccessControl`"
                  >
                    Access Control (IAM)
                  </a>
                  in the Azure portal, then click <b>Add > Add role assignment</b>
                </li>
                <li>
                  Click <b>Privileged administrator roles</b>, select the <b>Owner</b> role, then click <b>Next</b>
                </li>
                <li>Add the <b>ProsperOps</b> service principal as a member, then click <b>Next</b></li>
                <li>Select <b>Allow user to assign all roles</b>, then click <b>Next</b></li>
                <li>Click <b>Review + assign</b></li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Owner</b> role. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Register Cost Management Exports Resource Provider"
            :open="activeAccordionKey === 'register-resource-providers'"
            :validate="validateResourceProviders"
          >
            <template #default>
              <ul>
                <li>
                  Open
                  <a
                    target="_blank"
                    :href="`https://portal.azure.com/#resource/subscriptions/${form.storageAccountSubscriptionId}/resourceproviders`"
                    >Resource providers</a
                  >
                  in the Azure portal
                </li>
                <li>Select <b>Microsoft.CostManagementExports</b> from the list of providers</li>
                <li>Click <b>Register</b></li>
              </ul>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate that the resource provider has been registered. If the issue persists,
                please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
        </AccordionList>
        <div class="mt-4">
          <button type="button" class="btn btn-link p-0" @click="prev">
            <BaseIcon name="arrow-left" class="mr-1" />
            Back to Azure Details
          </button>
        </div>
      </div>
    </div>
    <div v-if="cliValidationError" class="row">
      <div class="col-lg-12">
        <BoxMessage type="error" class="mb-4">{{ cliValidationError }}</BoxMessage>
      </div>
    </div>
    <div v-if="selectedOnboardingMethod === 'shell'" class="row">
      <div class="col-lg-4">
        <Panel>
          <ul class="mb-0 pl-3">
            <li class="mb-1">
              Open <a href="https://portal.azure.com/#cloudshell/" target="_blank">Cloud Shell</a> in the Azure portal
            </li>
            <li class="mb-1">Click <b>Switch to Bash</b> if your shell is in PowerShell</li>
            <li class="mb-1">Copy, paste, and execute the following script in the Azure Cloud Shell</li>
            <li class="mb-1">Once the script completes, click <b>Validate</b></li>
          </ul>
        </Panel>
      </div>
      <div class="col-lg-8">
        <CodePreview :code-as-text="onboardingScript" header-text="ProsperOps Limited Savings Analysis Access" />
      </div>
    </div>
    <div v-if="selectedOnboardingMethod === 'shell'" class="row sectional">
      <div class="col-sm-8 pt-2">
        <button type="button" class="btn btn-link p-0" @click="prev">
          <BaseIcon name="arrow-left" class="mr-1" />
          Back to Azure Details
        </button>
      </div>
      <div class="col pt-2">
        <div class="d-flex flex-row-reverse">
          <form-submit-button
            variant="primary"
            type="submit"
            :loading="loading"
            class="rounded-sm text-nowrap"
            @click="validateAllAccess"
          >
            Validate
            <template v-slot:loading> Validating... </template>
          </form-submit-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.onboardingCodeSnippet .codePreview pre {
  height: auto;
}
</style>

<style lang="scss" scoped>
.savingsAnalysisPermissions li + li {
  margin-top: 0.25rem;
}
</style>
