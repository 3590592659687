<script setup lang="ts">
import type { ChartLegendOptions } from '@console/components/charts/ChartLegendV2.vue';
import type { AwsOrganizationCommitmentLockInRiskTrend } from '@console/services/api.models';
import type { SeriesAreaOptions, SeriesLineOptions, TooltipOptions, XAxisOptions, YAxisOptions } from 'highcharts';

import moment from 'moment';
import { computed, onMounted, ref, watch } from 'vue';

import * as chartUtilities from '@console/components/charts/utility';
import { getMonthYAxis } from '@console/components/charts/utility';

import ChartLegendV2 from '@console/components/charts/ChartLegendV2.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

type Props = {
  baseline?: number | null;
  baselineDate?: string | null;
  trend: AwsOrganizationCommitmentLockInRiskTrend;
  dataThroughDate: string;
};

const props = withDefaults(defineProps<Props>(), {
  baseline: null,
  baselineDate: null,
});

const filteredTrendData = computed(() => props.trend.overall);

const MS_IN_DAY = 86400000;
const msStart = computed(() => {
  const seriesDayCounts = filteredTrendData.value.length;
  const dayCount = seriesDayCounts - 1;
  const dataThroughDate = props.dataThroughDate;
  return +new Date(dataThroughDate) - MS_IN_DAY * dayCount;
});

type SeriesDataPoint = [number, number | null];
const toDataPoint = (element: number | null, index: number): SeriesDataPoint => {
  var x = msStart.value + index * MS_IN_DAY;
  var y = element;
  return [x, y];
};

type SeriesOptions = Omit<SeriesLineOptions | SeriesAreaOptions, 'name' | 'data' | 'color'> & {
  name: string;
  data: Array<SeriesDataPoint>;
  color: string;
};

const series = computed<Array<SeriesOptions>>(() => {
  const sharedOptions = {
    marker: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 1,
      valueSuffix: ' months',
    },
  };

  var rv: SeriesOptions[] = [
    {
      name: 'Commitment Lock-In Risk with ProsperOps',
      type: 'area',
      color: '#a7a3ff',
      fillOpacity: 0.2,
      // This needs to be explicitly set to undefined, when it is not set
      // toggling it in the legend causes it to become dashed
      dashStyle: undefined,
      data: filteredTrendData.value.map(toDataPoint),
      ...sharedOptions,
    } as SeriesOptions,
  ];

  if (props.baseline) {
    rv.push({
      name: 'Commitment Lock-In Risk before ProsperOps (baseline)',
      type: 'line',
      color: '#495057',
      dashStyle: 'Dash',
      data: filteredTrendData.value.map((v, index) => {
        if (props.baselineDate) {
          var timestamp = moment(msStart.value + index * MS_IN_DAY);
          var baselineDate = moment(props.baselineDate);
          if (timestamp.isSameOrAfter(baselineDate)) {
            return toDataPoint(props.baseline, index);
          }
        }
        return toDataPoint(v == null ? null : props.baseline, index);
      }),
      ...sharedOptions,
    });
  }

  return rv;
});

const tooltip: TooltipOptions = {
  enabled: true,
  borderRadius: 0,
  borderColor: '#adb5bd',
  shared: true,
  shadow: false,
  xDateFormat: '<span style="font-size: 12px">%B %d, %Y</span>',
  useHTML: true,
  dateTimeLabelFormats: {
    millisecond: '%b %e, %Y',
  },
};

const xAxis: XAxisOptions = {
  type: 'datetime',
  tickLength: 0,
  labels: {
    formatter: chartUtilities.datetimeZoomLabelFormatter,
  },
  units: chartUtilities.units,
};

const yAxis = computed<YAxisOptions[]>(() => {
  const allValues = [...filteredTrendData.value];
  if (props.baseline) {
    allValues.push(props.baseline);
  }
  const maxMonths = Math.max(...(allValues as number[]));
  return [getMonthYAxis(maxMonths)];
});

const legend = ref<ChartLegendOptions[]>([]);
const buildLegend = () => {
  legend.value = series.value.map(s => ({
    label: s.name,
    color: s.color,
    selected: true,
  }));
};
watch(series, buildLegend);
onMounted(buildLegend);

const filteredSeries = computed(() => series.value.filter(s => legend.value.find(l => l.label === s.name)?.selected));
</script>

<template>
  <div>
    <ChartLegendV2 v-model:model-value="legend" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :tooltip="tooltip" :series="filteredSeries" zoomable />
  </div>
</template>
