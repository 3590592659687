<script>
import _ from 'lodash';
import moment from 'moment';

import { getCurrencyFormat, percentFormat } from '@console/components/charts/utility';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

export default {
  components: {
    ChartLegend,
    SeriesChart,
    PanelSection,
  },
  props: {
    dailySpendCoverage: {
      type: Array,
      required: true,
    },
    monthStart: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      series: [],
    };
  },
  computed: {
    xAxis() {
      const month = moment.utc(this.monthStart).format('MMM');
      const monthAndDay = ({ day }) => `${month} ${day}`;
      const categories = _.map(this.dailySpendCoverage, monthAndDay);
      return { categories };
    },
    yAxis() {
      const rightTickPositions = _.range(0, 120, 20);
      return [
        {
          min: 0,
          tickAmount: rightTickPositions.length,
          title: {
            text: null,
          },
          labels: {
            format: getCurrencyFormat(),
          },
        },
        {
          tickPositions: rightTickPositions,
          title: {
            text: null,
          },
          labels: {
            format: percentFormat,
          },
          opposite: true,
        },
      ];
    },
    filteredSeries() {
      return _.filter(this.series, s => s.legendSelected);
    },
  },
  mounted() {
    // This is the order the items will be stacked in the chart (the first will be on the top)
    const seriesDefs = [
      // Force this series to be the first in the legend (normally it would be last)
      { name: 'Compute Usage', field: 'compute_engine_usage', type: 'column', color: '#a7a3ff', legendOrder: 0 },

      { name: 'Smart Spend Coverage', field: 'smart_spend_coverage', type: 'area', color: '#8fffdf' },
      { name: 'Base Spend Coverage', field: 'base_spend_coverage', type: 'area', color: '#00c58c' },
      { name: 'Inherited Spend Coverage', field: 'inherited_spend_coverage', type: 'area', color: '#495057' },
      { name: 'Unbilled Spend Coverage', field: 'unbilled_spend_coverage', type: 'area', color: '#e6e6e6' },

      // Force this series to be the last in the legend (normally it would be first)
      {
        name: 'Overall Spend Coverage',
        field: 'overall_spend_coverage_percentage',
        type: 'line',
        color: '#fcbe2c',
        yAxis: 1,
        legendOrder: 100,
      },
    ];

    const columnOptions = s => ({
      borderColor: s.color, // For columnar series, use the fill color as the border color (the default is white)
    });
    const areaOrLineOptions = {
      stacking: 'normal',
      fillOpacity: '0.5',
      marker: {
        enabled: false,
      },
    };
    const dollarTooltip = { valuePrefix: '$', valueSuffix: '', valueDecimals: 2 };
    const percentTooltip = { valuePrefix: '', valueSuffix: '%', valueDecimals: 1 };

    this.series = seriesDefs
      .map((s, index) => ({
        label: s.name,
        tooltip: s.yAxis === 1 ? percentTooltip : dollarTooltip,
        // Use null as the default vaule to ensure that days without values are still shown on the x axis
        data: this.dailySpendCoverage.map(d => d[s.field] ?? null),
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: seriesDefs.length - index,
        legendSelected: true,
        dashStyle: 'solid',
        yAxis: 0,
        // Apply the options specific to the series type
        ...(s.type === 'column' ? columnOptions(s) : areaOrLineOptions),
        // Allow the series to override any of the above properties
        ...s,
      }))
      // Remove any series without any data points
      .filter(s => s.data.some(d => !!d));
  },
};
</script>

<template>
  <PanelSection header="Daily Spend Coverage">
    <div>
      <ChartLegend v-model="series" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" />
    </div>
  </PanelSection>
</template>
