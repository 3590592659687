import { notNewOrganization, hasSingleOrgSelected } from './requirements';
import {
  activeSubscription,
  atLeastRole,
  hasRoles,
  isFeatureEnabled,
  notInDemo,
  requires,
  selectedCompany,
} from '@console/router/requirements';
import { PermissionLevel } from '@shared/state/auth.store';

import CostsAvoided from '@aws/views/arm/scheduler/CostsAvoided.vue';
import Resources from '@aws/views/arm/scheduler/Resources.vue';

export const ArmRoutes = [
  {
    path: '/aws/arm/scheduler/costs_avoided',
    name: 'aws_arm_scheduler_costs_avoided',
    component: CostsAvoided,
    beforeEnter: requires(
      isFeatureEnabled('armScheduler'),
      notInDemo,
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/arm/scheduler/resources',
    name: 'aws_arm_scheduler_resources',
    component: Resources,
    beforeEnter: requires(
      isFeatureEnabled('armScheduler'),
      notInDemo,
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
];
