<script setup lang="ts">
import type { TotalRemainingCommitment, TotalRemainingCommitmentLineItem } from '@console/services/gcp/api.models';

import { computed } from 'vue';

import { getColor } from '../commitment_utility.ts';

import SectionChart from '@console/components/charts/SectionChart.vue';
import SectionTable from '@console/components/charts/SectionTable.vue';

const props = defineProps<{
  totalRemainingCommitment: TotalRemainingCommitment;
}>();

const sections = computed(() => {
  const lineItems = props.totalRemainingCommitment.line_items;
  const elements = lineItems.map(sectionElement);
  return elements;
});

const total = computed(() => {
  const commitment = props.totalRemainingCommitment;
  return {
    key: 'Total Commitment',
    value: 100,
    amount: commitment.overall_dollars,
  };
});

const sectionElement = (lineItem: TotalRemainingCommitmentLineItem, index: number) => {
  const color = getColor(lineItem.label, index);
  return {
    key: lineItem.label === 'other' ? 'Others' : lineItem.label,
    color: color,
    value: lineItem.percentage,
    amount: lineItem.dollars,
  };
};
</script>

<template>
  <div class="totalRemainingCommitment">
    <div class="chart d-flex justify-content-center pt-5">
      <SectionChart :sections="sections" :thumb="{ visible: false }" />
    </div>
    <SectionTable class="table mt-2" :sections="sections" :total="total" />
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.totalRemainingCommitment {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  max-width: 850px;
  height: 100%;
  margin: auto;

  :deep(tr:not(:last-child)) {
    th,
    td {
      padding-top: 0.5rem !important;
    }
  }
}

.totalRemainingCommitment > div:nth-child(1),
.totalRemainingCommitment > div:nth-child(2) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
</style>
