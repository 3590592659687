<script>
export default {
  props: {
    target: {
      type: [String, Function],
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'top',
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<template>
  <b-popover
    :target="target"
    :placement="placement"
    :custom-class="['rounded-0', customClass]"
    triggers="hover"
    boundary="viewport"
  >
    <slot></slot>
  </b-popover>
</template>
