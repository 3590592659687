<script setup lang="ts">
import { computed, withDefaults, defineProps, ref } from 'vue';

import awsService from '@aws/services/awsService';
import { useVuexStore } from '@console/state/vuex/store';

import DataExportSettingRow from './DataExportSettingRow.vue';
import CodePreview from '@shared/design/CodePreview.vue';
import BaseIcon from '@shared/design/icons/BaseIcon.vue';
import ModalV2 from '@shared/design/ModalV2.vue';
import Tooltip from '@shared/design/Tooltip.vue';

const vuexStore = useVuexStore();

interface Props {
  s3BucketName: string | null;
  isLoading: boolean;
  isDataExportEnabled: boolean;
}

const props = withDefaults(defineProps<Props>(), { isLoading: false });
const emits = defineEmits(['update:s3BucketName']);

const awsAccountFriendlyName = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.friendly_name
);
const awsAccountNumber = computed(
  () => vuexStore.getters['aws/selectedOrganizationManagementAccount']?.aws_account_number
);

const showConfigurePermissionsModal = ref(false);
const permissionRole = ref('');
const permissionPolicy = ref('');
async function onShowConfigurePermissionsModal() {
  showConfigurePermissionsModal.value = true;

  const iamDetails = await awsService.getIamDetails(vuexStore.getters['aws/selectedOrganizationManagementAccount'].id);
  permissionRole.value = iamDetails.iam_role_name;
  permissionPolicy.value =
    iamDetails.configuration_variants.find(v => v.configuration_variant === 'AWSConsole')?.configuration ?? '';
}
</script>

<template>
  <DataExportSettingRow>
    <template #label>
      <div>S3 Bucket Name</div>
    </template>
    <template #value>
      <div class="grid">
        <div>
          <input
            :value="props.s3BucketName"
            class="form-control form-control-sm bucketFormInput"
            placeholder="e.g. prosperops-data-export"
            autocomplete="off"
            :disabled="props.isDataExportEnabled || props.isLoading"
            @blur="event => emits('update:s3BucketName', (event.target as HTMLInputElement)?.value)"
          />
          <div>
            <button
              :disabled="!props.s3BucketName || props.isDataExportEnabled || props.isLoading"
              type="button"
              class="btn btn-dark btn-sm rounded-sm configurePermissions"
              @click="onShowConfigurePermissionsModal()"
            >
              Configure Permissions
            </button>
          </div>
        </div>
        <div v-if="!props.isDataExportEnabled">
          Note: Must be in the AWS Management account
          <strong> {{ awsAccountFriendlyName }}</strong> ({{ awsAccountNumber }})
        </div>
        <div v-if="props.isDataExportEnabled">
          <BaseIcon id="how-to-change-bucket-name-when-enabled" name="info-circle" class="changeBucketTooltip" />
          <Tooltip target="how-to-change-bucket-name-when-enabled">
            <span>To change the S3 bucket name, you must first disable Data Export.</span>
          </Tooltip>
        </div>
      </div>
    </template>
  </DataExportSettingRow>
  <ModalV2 v-model="showConfigurePermissionsModal" size="xl" title="Configure Data Export Permissions">
    <div class="mb-3">
      Additional IAM permissions are required for ProsperOps to write data to the specified S3 bucket. Please change the
      IAM policy on the following role to the updated policy below. Once complete, close the window and
      <strong>Enable</strong> Data Export.
    </div>
    <div>
      <ul>
        <li>
          AWS Account: <strong> {{ awsAccountFriendlyName }}</strong> ({{ awsAccountNumber }})
        </li>
        <li>
          Role: <strong>{{ permissionRole }}</strong>
        </li>
      </ul>
      <div>
        <CodePreview
          code-source="AWSConsole"
          :code-as-text="permissionPolicy"
          :header-text="`${permissionRole} IAM Policy`"
        />
      </div>
    </div>
  </ModalV2>
</template>

<style scoped lang="scss">
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.bucketFormInput {
  width: 350px;
}
.bucketFormInput:disabled {
  color: $gray-600;
  background-color: $gray-400;
}
.bucketFormInput::placeholder {
  color: #bcbccb;
}
.configurePermissions {
  margin-top: 10px;
  background-color: #6e757c;
}
.configurePermissions:disabled {
  color: rgb(255, 255, 255, 0.8);
  background-color: #cfcfcf;
  border-color: #cfcfcf;

  &.btn:disabled {
    opacity: 100;
  }
}
.changeBucketTooltip {
  margin-top: 7px;
  color: $gray-600;
}
.cursorHover:hover {
  cursor: pointer;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

@include media-breakpoint-down(lg) {
  .grid {
    display: flex;
    flex-direction: column;
  }
}
</style>
