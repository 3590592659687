<script>
import { defineComponent } from 'vue';

import storage from '@shared/lib/local_storage';
import { useFeatureFlag } from '@shared/state/feature.store.ts';

import BaseIcon from '@shared/design/icons/BaseIcon.vue';

const legacyLocalStorageKey = 'dismissAnnouncementBanner';
const localStorageKey = 'announcementBanner';
const gcpKey = 'GOOGLE_CLOUD_GA';
const azureKey = 'AZURE_EA';

export default defineComponent({
  name: 'AnnouncementBanner',
  components: {
    BaseIcon,
  },
  data() {
    const announcementState = storage.get(localStorageKey) ?? {};
    return {
      announcementState,
      gcpKey,
      azureKey,
    };
  },
  computed: {
    showGcpBanner() {
      return this.showBanner(gcpKey);
    },
    showAzureBanner() {
      return useFeatureFlag('azure').value && this.showBanner(azureKey);
    },
  },
  mounted() {
    storage.remove(legacyLocalStorageKey);
  },
  methods: {
    showBanner(name) {
      return !this.announcementState[name]?.dismissed;
    },
    dismiss(name) {
      this.announcementState = {
        ...this.announcementState,
        [name]: { dismissed: true },
      };
      storage.set(localStorageKey, this.announcementState);
    },
  },
});
</script>

<template>
  <div>
    <div v-if="showAzureBanner" class="bannerNavy azure">
      <button class="btn bannerDismiss" data-test-id="azureDismiss" @click="dismiss(azureKey)">
        <BaseIcon name="times" />
      </button>
      <div class="bannerContent d-flex justify-content-center">
        <div class="copy">
          <div>
            Autonomous Discount Management for Azure Compute is now in&nbsp;
            <a
              href="https://www.prosperops.com/blog/introducing-autonomous-discount-management-for-azure-compute/"
              target="_blank"
              >Early Access<BaseIcon name="external-link-alt" class="ml-2"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showGcpBanner" class="bannerGreen gcp">
      <button class="btn bannerDismiss" data-test-id="gcpDismiss" @click="dismiss(gcpKey)">
        <BaseIcon name="times" />
      </button>
      <div class="bannerContent d-flex justify-content-center">
        <div class="copy">
          <div>
            Autonomous Discount Management for Google Cloud Compute is now&nbsp;
            <a
              href="https://www.prosperops.com/blog/autonomous-discount-management-for-google-cloud-compute-generally-available/"
              target="_blank"
              >Generally Available<BaseIcon name="external-link-alt" class="ml-2"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.bannerGreen {
  color: $white;
  background-color: map-get($theme-colors, 'success');
  a {
    color: #8fffdf;
    text-decoration: underline;
    text-shadow: none;
  }
}
.bannerNavy {
  color: $white;
  background-color: #05004dc8;
  a {
    color: $gray-400;
    text-decoration: underline;
    text-shadow: none;
  }
}

.banner {
  padding: 0.5rem 1rem;
  font-weight: 500;
}

.bannerContent {
  padding: 0.5rem 1rem;
  font-weight: 500;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
}

.bannerDismiss {
  float: right;
  padding: 0.5rem 1rem;
  color: $white;
  border: 0;
}
</style>

<style lang="scss">
@import '@shared/scss/colors.scss';
</style>
