<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { computed, ref } from 'vue';

import { useAzureStore } from '@azure/state';
import { useVuexStore } from '@console/state/vuex/store';

import ManagementPermissions from '@azure/components/onboarding/ManagementPermissions.vue';
import PrelaunchPlaceholder from '@azure/components/onboarding/PrelaunchPlaceholder.vue';
import SavingsAnalysisPermissions from '@azure/components/onboarding/SavingsAnalysisPermissions.vue';
import Start from '@azure/components/onboarding/Start.vue';
import OnboardingProgress from '@console/components/onboarding/OnboardingProgress.vue';
import Layout from '@console/Layout.vue';

useHead({
  title: 'Azure Onboarding',
});

type OnboardingStep = 'start' | 'savings-analysis-permissions' | 'schedule-savings-analysis' | 'management-permissions';

const azureState = useAzureStore();
const vuexStore = useVuexStore();

const showPostSavingsAnalysisSteps = computed(() => {
  const context = vuexStore.getters['nav/context'];
  const scope = azureState.getBillingScopeById(context.id);
  const settings = azureState.getGlobalSettingsById(context.id);

  return scope && settings?.savings_analysis_completed;
});

const currentStep = ref<OnboardingStep>(
  (() => {
    const context = vuexStore.getters['nav/context'];
    const scope = azureState.getBillingScopeById(context.id);

    if (showPostSavingsAnalysisSteps.value) {
      return 'management-permissions';
    } else if (scope) {
      return 'schedule-savings-analysis';
    }

    return 'start';
  })()
);

const preSavingsAnalysisSteps: { text: string; key: OnboardingStep; number: number }[] = [
  {
    text: 'Azure Details',
    key: 'start',
    number: 1,
  },
  {
    text: 'Initial Azure Access',
    key: 'savings-analysis-permissions',
    number: 2,
  },
  {
    text: 'Free Savings Analysis',
    key: 'schedule-savings-analysis',
    number: 3,
  },
];

const postSavingsAnalysisSteps = [
  ...preSavingsAnalysisSteps,
  {
    text: 'Additional Azure Access',
    key: 'management-permissions',
    number: 4,
  },
];

const progressTrackerSteps = computed(() => {
  const stepsToShow = showPostSavingsAnalysisSteps.value ? postSavingsAnalysisSteps : preSavingsAnalysisSteps;
  return stepsToShow.map(step => ({ ...step, isActive: step.key === currentStep.value }));
});
</script>

<template>
  <Layout :with-announcement-banner="false">
    <template #default>
      <OnboardingProgress :steps="progressTrackerSteps" class="mb-2" />
      <Start v-if="currentStep === 'start'" :next="() => (currentStep = 'savings-analysis-permissions')" />
      <SavingsAnalysisPermissions
        v-if="currentStep === 'savings-analysis-permissions'"
        :next="() => (currentStep = 'schedule-savings-analysis')"
        :prev="() => (currentStep = 'start')"
      />
      <PrelaunchPlaceholder v-if="currentStep === 'schedule-savings-analysis'" />
      <ManagementPermissions v-if="currentStep === 'management-permissions'" :next="() => {}" />
    </template>
  </Layout>
</template>
