import type {
  CustomerDataExportStatusResponse,
  CustomerSettingResponse,
  IamDetails,
  RegionResponse,
} from '@console/services/api.models';

import { core } from '@shared/services/api';

export enum EditableAwsOrgSettings {
  IsCustomerDataExportEnabled = 'IsCustomerDataExportEnabled',
  CustomerDataExportS3BucketName = 'CustomerDataExportS3BucketName',
}

class AwsService {
  async getIamDetails(awsAccountId: string): Promise<IamDetails> {
    const res = await core.get<IamDetails>(`/v1/aws_accounts/${awsAccountId}/iam_details`);
    return res.data;
  }

  async getRegions() {
    const res = await core.get<Array<RegionResponse>>(`/v1/aws/regions`);
    return res.data;
  }

  async setSetting(awsOrgId: string, settingName: EditableAwsOrgSettings, settingValue: string | number) {
    const res = await core.put<CustomerSettingResponse>(
      `/v1/aws_organizations/${awsOrgId}/settings/${settingName}?value=${encodeURIComponent(settingValue)}`
    );
    return res.data;
  }

  async enableSetting(awsOrgId: string, settingName: EditableAwsOrgSettings) {
    const res = await core.put<CustomerSettingResponse>(`/v1/aws_organizations/${awsOrgId}/settings/${settingName}`);
    return res.data;
  }

  async disableSetting(awsOrgId: string, settingName: EditableAwsOrgSettings) {
    const res = await core.delete<CustomerSettingResponse>(`/v1/aws_organizations/${awsOrgId}/settings/${settingName}`);
    return res.data;
  }

  async getCustomerDataExportStatus(awsOrgId: string): Promise<CustomerDataExportStatusResponse> {
    const res = await core.get<CustomerDataExportStatusResponse>(
      `/v1/aws_organizations/${awsOrgId}/data-export/status`
    );
    return res.data;
  }

  async enableCustomerDataExport(awsOrgId: string): Promise<CustomerDataExportStatusResponse> {
    const res = await core.put<CustomerDataExportStatusResponse>(
      `/v1/aws_organizations/${awsOrgId}/data-export/enable`
    );
    return res.data;
  }

  async disableCustomerDataExport(awsOrgId: string): Promise<CustomerDataExportStatusResponse> {
    const res = await core.put<CustomerDataExportStatusResponse>(
      `/v1/aws_organizations/${awsOrgId}/data-export/disable`
    );
    return res.data;
  }
}

const awsService = new AwsService();
export default awsService;
