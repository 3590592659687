<script setup lang="ts">
import type { ColDef } from '@ag-grid-community/core';
import type { Ec2InventoryItem } from '@console/services/aws/arm/api.models';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from '@ag-grid-community/vue3';
import { onMounted, ref } from 'vue';

import { getEc2Inventory } from '@aws/services/awsArmService';
import { useVuexStore } from '@console/state/vuex/store';

import Layout from '@console/Layout.vue';

const store = useVuexStore();

const rowData = ref<Ec2InventoryItem[]>([]);
const colDefs = ref<Array<ColDef<Ec2InventoryItem>>>([
  { headerName: 'Name', valueGetter: i => i.data?.instance_id },
  { headerName: 'Account', valueGetter: i => `${i.data?.aws_account_number} (${i.data?.friendly_name})`, filter: true },
  { headerName: 'State', valueGetter: i => i.data?.last_state_name },
  { headerName: 'Power On Schedule', valueGetter: i => i.data?.power_on_schedule },
  { headerName: 'Power Off Schedule', valueGetter: i => i.data?.power_off_schedule },
  { headerName: 'Timezone', valueGetter: i => i.data?.timezone },
  { headerName: 'Notification Receiver', valueGetter: i => i.data?.notification_email },
]);

onMounted(async () => {
  rowData.value = await getEc2Inventory(store.getters['aws/selectedOrganizationId']);
});
</script>

<template>
  <Layout>
    <template #default>
      <AgGridVue
        :row-data="rowData"
        :column-defs="colDefs"
        suppress-movable-columns
        :pagination="true"
        :pagination-page-size="10"
        :pagination-page-size-selector="false"
        :auto-size-strategy="{ type: 'fitGridWidth' }"
        :modules="[ClientSideRowModelModule]"
        style="width: 100%; height: 90%"
        class="ag-theme-quartz"
      />
    </template>
  </Layout>
</template>
