import Onboarding from '@azure/views/Onboarding.vue';

export const routes = [
  {
    path: '/azure/onboarding',
    name: 'azure_onboarding',
    component: Onboarding,
    meta: {
      initModules: true,
    },
  },
];
