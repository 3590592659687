<script setup lang="ts">
import type { CustomerDataExportStatusResponse } from '@console/services/api.models';

import { onMounted, ref, watch } from 'vue';

import awsService, { EditableAwsOrgSettings } from '@aws/services/awsService';
import { useVuexStore } from '@console/state/vuex/store';

import CustomerDataExportS3BucketName from './CustomerDataExportS3BucketName.vue';
import DataExportToggle from './DataExportToggle.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const vuexStore = useVuexStore();

const isLoading = ref(false);

const s3EditValue = ref(vuexStore.getters['aws/selectedOrganizationSettings']?.customer_data_export_s3_bucket_name);
const isCustomerDataExportEnabled = ref<boolean>(false);
const settingLastUpdated = ref<string | null>(null);
const lastExportDate = ref<string | null>(null);
const iamValidationError = ref<{ message: string; date: string } | null>(null);

async function onToggle(isEnabled: boolean) {
  isLoading.value = true;

  const status = isEnabled
    ? await awsService.enableCustomerDataExport(vuexStore.getters['aws/selectedOrganizationId'])
    : await awsService.disableCustomerDataExport(vuexStore.getters['aws/selectedOrganizationId']);

  setStatusState(status);
  await vuexStore.dispatch('aws/initializeAwsOrganizationSettings');

  isLoading.value = false;
}

watch(s3EditValue, async (newValue: string, oldValue: string) => {
  const hasChanged = newValue !== oldValue; // without this check the watch can trigger multiple times making multiple api calls.
  if (hasChanged) {
    isLoading.value = true;

    if (newValue) {
      await awsService.setSetting(
        vuexStore.getters['aws/selectedOrganizationId'],
        EditableAwsOrgSettings.CustomerDataExportS3BucketName,
        newValue
      );
    } else {
      await awsService.disableSetting(
        vuexStore.getters['aws/selectedOrganizationId'],
        EditableAwsOrgSettings.CustomerDataExportS3BucketName
      );
    }

    await vuexStore.dispatch('aws/initializeAwsOrganizationSettings');

    const status = await awsService.getCustomerDataExportStatus(vuexStore.getters['aws/selectedOrganizationId']);
    setStatusState(status);

    isLoading.value = false;
  }
});

onMounted(async () => {
  isLoading.value = true;

  const status = await awsService.getCustomerDataExportStatus(vuexStore.getters['aws/selectedOrganizationId']);
  setStatusState(status);

  isLoading.value = false;
});

async function setStatusState(status: CustomerDataExportStatusResponse) {
  isCustomerDataExportEnabled.value = status.is_enabled;
  settingLastUpdated.value = status.setting_last_updated_date;
  lastExportDate.value = status.last_export_date;
  iamValidationError.value = status.iam_validation_error_message
    ? { message: status.iam_validation_error_message, date: status.iam_validation_error_date ?? '' }
    : null;
}
</script>

<template>
  <PanelSection header="" rounded new-header class="settingsConstrainedWidth">
    <template #header>
      <div class="container">
        <div class="row">
          <div class="col headerTitle">Data Export</div>
          <LastUpdated :value="settingLastUpdated" />
        </div>
      </div>
    </template>
    <div class="mt-2 text-muted dataExportDescription">
      Periodically export ProsperOps data (in CSV format) to an S3 bucket in your AWS Organization. This enables
      programmatic integration and/or ad hoc data analysis.
    </div>

    <CustomerDataExportS3BucketName
      v-model:s3-bucket-name="s3EditValue"
      :is-loading="isLoading"
      :is-data-export-enabled="isCustomerDataExportEnabled"
    />

    <DataExportToggle
      :toggle-value="isCustomerDataExportEnabled"
      :last-export-date="lastExportDate"
      :is-disabled="!s3EditValue || isLoading"
      :error="iamValidationError"
      @on-toggle="(isEnabled: boolean) => onToggle(isEnabled)"
    />
  </PanelSection>
</template>

<style lang="scss" scoped>
.settingsConstrainedWidth {
  max-width: 900px;

  :deep(section) {
    padding: 0.5rem 1.5rem 2rem;
  }
}
.headerTitle {
  padding: 0;
  font-weight: 500;
  vertical-align: top;
}
.dataExportDescription {
  font-size: 0.85rem;
}
</style>
