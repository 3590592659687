import type { AwsOrganizationUsageResponse } from '@console/services/api.models';

import { defineStore } from 'pinia';

import customerService from '@console/services/customerService';
import { getVuexStore } from '@console/state/vuex/store';

type AwsOrganizationId = string;

type AwsOrganizationState = {
  usage?: AwsOrganizationUsageResponse;
};

export type AwsState = {
  organizations: Map<AwsOrganizationId, AwsOrganizationState>;
};

export const useAwsStore = defineStore('aws', {
  state: (): AwsState => ({
    organizations: new Map<AwsOrganizationId, AwsOrganizationState>(),
  }),
  getters: {
    selectedOrganizationId(): string | undefined {
      const store = getVuexStore();
      return store.getters['aws/selectedOrganizationId'];
    },
    organization(): AwsOrganizationState | undefined {
      if (!this.selectedOrganizationId) return;
      return this.organizations.get(this.selectedOrganizationId);
    },
    usage(): AwsOrganizationUsageResponse | undefined {
      return this.organization?.usage;
    },
  },
  actions: {
    async loadOrganization() {
      if (!this.selectedOrganizationId) return;
      if (this.organization) return;

      const usageResponse = await customerService.getAwsServiceUsage(this.selectedOrganizationId);
      this.organizations.set(this.selectedOrganizationId, {
        usage: usageResponse?.data,
      });
    },
  },
});
