<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import {
  AgreementType,
  generateExportResourceGroupName,
  generateExportStorageAccountName,
} from '@azure/services/billingScope';
import * as servicePrincipal from '@azure/services/servicePrincipal';
import { validateAccess, AccessValidationStatus, PermissionSet } from '@azure/services/servicePrincipal';
import { useAzureStore } from '@azure/state';
import { useVuexStore } from '@console/state/vuex/store';

import ValidationStepperItem from '@gcp/components/onboarding/ValidationStepperItem.vue';
import AccordionList from '@shared/design/AccordionList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import CodePreview from '@shared/design/CodePreview.vue';

type ValidationStep = 'billing-account-access' | 'reservations-access' | 'savings-plan-access';

const props = defineProps<{
  next: () => void;
}>();

const azureState = useAzureStore();
const vuexStore = useVuexStore();
const activeAccordionKey = ref<ValidationStep>('billing-account-access');
const spn = ref<servicePrincipal.CreateServicePrincipalResponse>();

const billingScope = computed(() => {
  const context = vuexStore.getters['nav/context'];
  return azureState.getBillingScopeById(context.id)!;
});

const assignmentId = self.crypto.randomUUID();
const enterpriseAgreementCodeSnippet = computed(
  () => `SPN_OBJECT_ID=$(az rest --method get --url "https://graph.microsoft.com/v1.0/servicePrincipals(appId='${spn.value?.application_id}')" --query id --output tsv)

az rest --method put \\
  --uri "https://management.azure.com/providers/Microsoft.Billing/billingAccounts/${billingScope.value.billing_account_id}/billingRoleAssignments/${assignmentId}?api-version=2019-10-01-preview" \\
  --headers "Content-Type=application/json" \\
  --body "{
    \\"properties\\": {
      \\"principalId\\": \\"$SPN_OBJECT_ID\\",
      \\"principalTenantId\\": \\"${spn.value?.tenant_id}\\",
      \\"roleDefinitionId\\": \\"/providers/Microsoft.Billing/billingAccounts/${billingScope.value.billing_account_id}/billingRoleDefinitions/da6647fb-7651-49ee-be91-c43c4877f0c4\\"
    }
  }"
`
);

onMounted(async () => {
  spn.value = await servicePrincipal.get(billingScope.value.service_principal_id);
});

const validateManagementAccess = async () => {
  return await validateAccess(billingScope.value.service_principal_id, {
    agreement_type: billingScope.value.agreement_type,
    billing_account_id: billingScope.value.billing_account_id,
    billing_profile_id: billingScope.value.billing_profile_id,
    storage_account_subscription_id: billingScope.value.storage_account_subscription_id,
    storage_account_resource_group_name: generateExportResourceGroupName(),
    storage_account_name: generateExportStorageAccountName(billingScope.value.storage_account_subscription_id),
    permission_set: PermissionSet.Management,
  });
};

const validateBillingScopeAccess = async () => {
  const result = await validateManagementAccess();
  if (result.billing_scope_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.billing_scope_access.message);
  }

  activeAccordionKey.value = 'reservations-access';
};

const validateReservationsAccess = async () => {
  const result = await validateManagementAccess();
  if (result.reservations_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.reservations_access.message);
  }

  activeAccordionKey.value = 'savings-plan-access';
};

const validateSavingsPlanAccess = async () => {
  const result = await validateManagementAccess();
  if (result.savings_plan_access.status !== AccessValidationStatus.Validated) {
    throw new Error(result.savings_plan_access.message);
  }

  await azureState.activateBillingScope(billingScope.value.id);
  props.next();
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <h2>Additional Azure Access</h2>
        <div class="pt-2">
          <BoxMessage type="info">
            ProsperOps needs additional access to actively manage your commitments. Follow the steps below to configure
            access using the Azure Portal. If you need additional help, see
            <a href="#" target="_blank">this article</a> for detailed step-by-step instructions.
          </BoxMessage>
        </div>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <AccordionList class="mt-4 bg-white">
          <ValidationStepperItem
            title="Upgrade Billing Account Access"
            :open="activeAccordionKey === 'billing-account-access'"
            :validate="validateBillingScopeAccess"
          >
            <template #default>
              <ol v-if="billingScope.agreement_type === AgreementType.MicrosoftCustomerAgreement">
                <li>
                  Go to
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_CostManagement/Menu/~/overview"
                    target="_blank"
                  >
                    Cost Management + Billing
                  </a>
                  in the Azure portal
                </li>
                <li>Use <code>Scope</code> selector in header to change billing scope to desired billing profile</li>
                <li>Click <code>Access control</code> on left menu</li>
                <li>Click <code>Add</code></li>
                <li>Select role: <code>Billing Profile Contributor</code></li>
                <li>Add member: <code>ProsperOps</code></li>
                <li>Click <code>Add</code></li>
              </ol>
              <div v-else>
                Execute the following command from Azure Shell:
                <CodePreview :code-as-text="enterpriseAgreementCodeSnippet" class="onboardingCodeSnippet mt-2" />
              </div>
            </template>
            <template #error="{ error }">
              <span v-if="error?.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Billing Profile Contributor</b> role on the specified billing profile. If the issue persists, please
                chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Grant Reservations Access"
            :open="activeAccordionKey === 'reservations-access'"
            :validate="validateReservationsAccess"
          >
            <template #default>
              <ol>
                <li>
                  Follow steps to
                  <a
                    href="https://learn.microsoft.com/en-us/azure/role-based-access-control/elevate-access-global-admin?tabs=azure-portal#perform-steps-at-root-scope"
                    target="_blank"
                    >elevate your access
                  </a>
                </li>
                <li>
                  Go to
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_Reservations/ReservationsBrowseBlade/productType/Reservations"
                    target="_blank"
                  >
                    Reservations
                  </a>
                  in the Azure portal
                </li>
                <li>Click <code>Role Assignment</code> on header</li>
                <li>Click <code>Add > Add role assignment</code></li>
                <li>Select role: <code>Reservation Purchaser</code></li>
                <li>Click <code>Next</code></li>
                <li>Select member: <code>ProsperOps</code></li>
                <li>Click <code>Review + assign</code></li>
              </ol>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Reservation Purchaser</b> role. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
          <ValidationStepperItem
            title="Grant Savings Plan Access"
            :open="activeAccordionKey === 'savings-plan-access'"
            :validate="validateSavingsPlanAccess"
          >
            <template #default>
              <ol>
                <li>
                  Go to
                  <a
                    href="https://portal.azure.com/#view/Microsoft_Azure_Reservations/ReservationsBrowseBlade/productType/SavingsPlan"
                    target="_blank"
                  >
                    Savings plans
                  </a>
                  in the Azure portal
                </li>
                <li>Click <code>Role Assignment</code> on header</li>
                <li>Click <code>Add > Add role assignment</code></li>
                <li>Select role: <code>Savings plan Purchaser</code></li>
                <li>Click <code>Next</code></li>
                <li>Select member: <code>ProsperOps</code></li>
                <li>Click <code>Review + assign</code></li>
              </ol>
            </template>
            <template #error="{ error }">
              <span v-if="error.message">{{ error.message }}</span>
              <span v-else>
                Hmm, we weren't able to validate access. Please ensure the service principal has been granted the
                <b>Savings plan Purchaser</b> role. If the issue persists, please chat with us for help.
              </span>
            </template>
          </ValidationStepperItem>
        </AccordionList>
      </div>
    </div>
  </div>
</template>
