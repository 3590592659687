<script setup lang="ts">
import type { GetUserResponse } from '@shared/services/customer.models';
import type { User } from '@shared/state/users.store';

import _ from 'lodash';
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';

import { useAzureStore } from '@azure/state';
import { getRoleDescriptions } from '@console/components/users/role_descriptions';
import { useVuexStore } from '@console/state/vuex/store';
import { RoleTypes } from '@shared/services/customer.models';
import { GranularRole, PermissionLevel, convertCloudFromApiResponse, useAuthStore } from '@shared/state/auth.store';
import { useFeatureStore } from '@shared/state/feature.store';
import { useUsersStore } from '@shared/state/users.store';
import { dateFromNow, dateLocalize } from '@shared/utilities/filters';

import GranularRoleSelector from '@console/components/users/GranularRoleSelector.vue';
import UserImage from '@console/components/users/UserImage.vue';
import Layout from '@console/Layout.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import Panel from '@shared/design/panels/Panel.vue';

const store = useVuexStore();
const router = useRouter();
const authStore = useAuthStore();
const userStore = useUsersStore();
const azureStore = useAzureStore();
const featureStore = useFeatureStore();

const hasMoreThanOneResource = computed(() => {
  return (
    Object.values(store.state.aws.awsOrganizations).length +
      Object.values(store.state.gcp.billingAccounts).length +
      Object.values(azureStore.billingScopes ?? {}).length >
    1
  );
});
const roles = computed(() => {
  return getRoleDescriptions(hasMoreThanOneResource.value, featureStore.featureFlags.azure);
});

const user = ref<User | null>(null);
const selectedRole = ref<RoleTypes | null>(null);
const selectedGranularRoles = ref<GranularRole[]>([]);
const submitting = ref(false);

const isLoading = computed(() => user.value === null);
const roleChanged = computed(
  () =>
    selectedRole.value !== user.value?.user_role ||
    (selectedRole.value === RoleTypes.Granular &&
      !_.isEqual(
        selectedGranularRoles.value.map(r => r.toRoleString()),
        user.value?.granular_roles
      ))
);
const hasNoSelectedGranularRoles = computed(
  () => selectedRole.value === RoleTypes.Granular && selectedGranularRoles.value.length === 0
);
const isCurrentUser = computed(() => user.value?.email_address === authStore.email);
const createdDate = computed(() => dateFromNow(dateLocalize(user.value?.created_date)));

onMounted(async () => {
  try {
    // route params can be an array for any param but this one is definitely not
    // so explicitly check and throw if its not a string
    const emailAddress =
      typeof router.currentRoute.value.params.emailAddress === 'string'
        ? router.currentRoute.value.params.emailAddress
        : null;

    if (!emailAddress) {
      throw new Error(`Email address not provided in valid format: ${router.currentRoute.value.params.emailAddress}`);
    }

    if (userStore.users.length === 0) {
      // if we got here by directly going to the url we want to load all users in the background
      // otherwise after editing the state may not be accurate
      await userStore.refresh();
    } else {
      // even if we have the user we want to make sure we have the latest data before editing
      await userStore.refreshByEmail(decodeURIComponent(emailAddress));
    }

    const foundUser = userStore.getByEmail(decodeURIComponent(emailAddress));
    if (foundUser) {
      user.value = foundUser;
      selectedRole.value = user.value.user_role;
      selectedGranularRoles.value = user.value.granular_roles.map((role: string) => {
        const [customerId, cloud, resourceId, permissionLevel] = role.split(':');
        const parsedCloud = convertCloudFromApiResponse(cloud);
        const parsedPermissionLevel = PermissionLevel[permissionLevel as keyof typeof PermissionLevel];
        return new GranularRole(customerId, parsedCloud, resourceId, parsedPermissionLevel);
      });
    }
  } catch (error) {
    await router.push({ name: 'error' });
    throw error;
  }
});

const submit = async () => {
  submitting.value = true;

  if (!user.value || !selectedRole.value) {
    return;
  }

  if (selectedRole.value === RoleTypes.Granular) {
    await userStore.updateGranularRoles(user.value.email_address, selectedGranularRoles.value);
  } else {
    await userStore.updateUserRole(user.value.email_address, selectedRole.value);
  }
  submitting.value = false;
  await router.push({ name: 'user_management' });
};

const badgeClass = (user: GetUserResponse | null) => {
  if (!user) return '';

  return (
    {
      PendingEmailValidation: 'badge-warning',
      Active: 'badge-success',
    }[user.user_status] || 'badge-success'
  );
};

const badgeText = (user: GetUserResponse | null) => {
  if (!user) return '';

  return (
    {
      PendingEmailValidation: 'Pending Email Validation',
    }[user.user_status] || user.user_status
  );
};
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <div class="row pb-3">
        <div class="col">
          <router-link :to="{ name: 'user_management' }">
            <BaseIcon name="arrow-left" class="mr-1" />
            Back to User Management
          </router-link>
        </div>
      </div>
      <PageHeader wrap-utility>
        <div class="d-flex">
          <UserImage :user="user" />
          <h1 class="pl-3 mb-0">{{ user?.first_name }} {{ user?.last_name }}</h1>
        </div>
      </PageHeader>
      <div class="row pt-3">
        <div class="col pt-4">
          <Panel>
            <div class="topPanel">
              <div class="w-100 text-truncate">
                <div class="text-muted">Email Address</div>
                <div>
                  {{ user?.email_address }}
                </div>
              </div>
              <div class="w-100 d-flex justify-content-center align-items-center">
                <div class="badge badge-pill p-2 pr-3 pl-3" :class="badgeClass(user)">
                  {{ badgeText(user) }}
                </div>
              </div>
              <div class="w-100">
                <div class="text-muted">Created</div>
                <div
                  class="createdDate"
                  :title="user?.created_date ? new Date(user.created_date).toISOString() : undefined"
                >
                  {{ createdDate }}
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
      <form @submit.prevent="submit">
        <div class="row pt-4">
          <div class="col pt-4">
            <h3>Role Membership</h3>
            <ul class="list-group pt-2 roleMembership">
              <li v-for="role in roles" :key="role.name" class="list-group-item rounded-sm">
                <div class="role">
                  <div class="roleOption">
                    <input
                      :id="role.name"
                      v-model="selectedRole"
                      type="radio"
                      name="role"
                      :value="role.name"
                      :disabled="isCurrentUser"
                    />
                  </div>
                  <label :for="role.name" class="roleText w-100" :class="{ roleTextActive: !isCurrentUser }">
                    <div class="pb-2 roleName">
                      {{ role.name === RoleTypes.Granular ? 'Custom' : role.name }}
                    </div>
                    <p class="mb-0 text-muted">
                      {{ role.text }}
                    </p>
                  </label>
                </div>
                <div v-if="role.name === RoleTypes.Granular && selectedRole === RoleTypes.Granular" class="ml-5">
                  <GranularRoleSelector
                    v-model:selected-granular-roles="selectedGranularRoles"
                    @update:selected-granular-roles="(updatedGranularRoles: GranularRole[]) => {
                      selectedGranularRoles = updatedGranularRoles}"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isCurrentUser" class="row pt-4">
          <div class="col pt-4">
            <div class="buttons">
              <form-submit-button
                type="submit"
                variant="primary"
                class="rounded-sm"
                :disabled="!roleChanged || hasNoSelectedGranularRoles"
                :loading="submitting"
              >
                Update Role
                <template v-slot:loading> Updating... </template>
              </form-submit-button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.topPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
  }

  > div:first-child {
    padding-bottom: 1rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
      text-align: left;
    }
  }

  > div:last-child {
    padding-top: 1rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      text-align: right;
    }
  }
}

.required::before {
  display: inline-block;
  font-weight: 800;
  color: map-get($theme-colors, 'danger');
  content: '*';
}

.role {
  display: flex;
}

.roleText {
  padding: 0.7rem;
  font-size: 1rem;
  line-height: 1.2;
}

.roleTextActive:hover {
  cursor: pointer;
}

.roleOption {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
  padding: 0.7rem;
}

.roleName {
  font-weight: 500;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
}

.createdDate {
  text-transform: capitalize;
}

.statusSection {
  width: 180px;
}

.roleMembership li {
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}
</style>
