<script setup lang="ts">
const props = defineProps({
  enabled: {
    // Used as the current value of the toggle
    type: Boolean,
    required: true,
  },
  off: {
    type: String,
    required: true,
  },
  on: {
    type: String,
    required: true,
  },
  disabled: {
    // Used for disabling the toggle as a whole (not editable) not as a value
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits<{ (e: 'change', isEnabled: boolean): void }>();
const toggle = () => {
  if (!props.disabled) {
    emits('change', !props.enabled);
  }
};
</script>

<template>
  <div class="toggleContainer" :class="{ enabled, disabled }" @click="toggle()">
    <span v-if="enabled" class="onText">{{ props.on }}</span>
    <div class="bubble" :class="{ on: enabled, off: !enabled }" />
    <span v-if="!enabled" class="offText">{{ props.off }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.toggleContainer {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 65px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  background-color: #bcbccb;
  border: 1px solid #bcbccb;
  border-radius: 18px;

  &.disabled {
    color: rgb(255, 255, 255, 0.8);
    cursor: default;
    background-color: #cfcfcf;
    border: 1px solid #cfcfcf;

    .onText,
    .offText {
      color: rgb(255, 255, 255, 0.8);
      cursor: default;
    }

    .bubble {
      background-color: #e6e6e6;
    }
  }

  &:focus {
    border-color: white;
  }

  &.enabled {
    background-color: map-get($theme-colors, 'success');
    border: 1px solid map-get($theme-colors, 'success');
  }

  .bubble {
    width: 23px;
    height: 23px;
    margin-top: 2px;
    margin-left: 1px;
    background-color: white;
    border-radius: 100%;

    &.on {
      margin-left: 5px;
    }
    &.off {
      margin-left: 2px;
    }
  }

  .onText,
  .offText {
    padding-top: 3px;
    color: white;
    cursor: pointer;
  }

  .onText {
    margin-left: 12px;
  }

  .offText {
    margin-right: 8px;
  }
}
</style>
