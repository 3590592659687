<script setup lang="ts">
import { computed } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';

import NavigationHeader from '@console/components/navigation/NavigationHeader.vue';
import NavigationLink from '@console/components/navigation/NavigationLink.vue';
import NavigationList from '@console/components/navigation/NavigationList.vue';
import NavigationSection from '@console/components/navigation/NavigationSection.vue';

const store = useVuexStore();

const isSubscribed = computed(() => store.getters['customer/isSubscribed']);

const context = computed(() => store.getters['nav/context']);
const isOnboarding = computed(() => store.getters['nav/isOnboarding']);

const showOnboardingNav = computed(() => {
  if (isOnboarding.value) {
    return true;
  }
  if (!store.getters['gcp/isBillingAccountOnboarded'](context.value.id)) {
    return true;
  }
  return !isSubscribed.value;
});
</script>

<template>
  <div>
    <nav v-if="showOnboardingNav">
      <NavigationList>
        <li>
          <NavigationLink icon="onboarding-icon" :to="{ name: 'google_cloud_onboarding' }" custom-icon>
            Onboarding
          </NavigationLink>
        </li>
      </NavigationList>
    </nav>
    <nav v-else>
      <div>
        <NavigationHeader>Autonomous Discount Management</NavigationHeader>
        <NavigationSection icon="gcp-compute-engine-icon" data-test="nav-section-compute-engine">
          <template #header> Compute </template>
          <template #items>
            <li>
              <NavigationLink
                icon="savings-icon"
                :to="{ name: 'google_cloud_compute_savings' }"
                custom-icon
                indent
                compact
              >
                Savings
              </NavigationLink>
            </li>
            <li>
              <NavigationLink
                icon="commitments-icon"
                :to="{ name: 'google_cloud_compute_commitments' }"
                custom-icon
                indent
                compact
              >
                Commitments
              </NavigationLink>
            </li>
            <li>
              <NavigationLink icon="cog" :to="{ name: 'google_cloud_compute_settings' }" indent compact
                >Settings</NavigationLink
              >
            </li>
          </template>
        </NavigationSection>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.earlyAccessBadge {
  margin-left: 0.75rem;
}
</style>
