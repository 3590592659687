<script setup>
import _ from 'lodash';
import { computed, ref } from 'vue';

import { getColor } from '../commitment_utility';
import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegendV2.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const MS_IN_DAY = 86400000;
const DAYS_TO_SHOW = 1095;

const props = defineProps({
  commitmentBurndown: {
    type: Object,
    required: true,
  },
  dataThroughDate: {
    type: String,
    required: true,
  },
});

const tooltip = chartUtilities.tooltipWithTotal({
  totalLabel: 'Commitment Total',
  valueFormatter: v => NumberHelpers.formatDollars(v, 0),
  xLabelFormatter: x => chartUtilities.dateFormat('%B %d, %Y', x),
});

const xAxis = computed(() => {
  return {
    type: 'datetime',
    labels: {
      formatter: chartUtilities.datetimeZoomLabelFormatter,
    },
    tickLength: 0,
    units: chartUtilities.units,
  };
});

const yAxis = [
  {
    min: 0,
    title: {
      text: null,
    },
    labels: {
      format: chartUtilities.getCurrencyFormat(),
    },
  },
];

const seriesElements = computed(() => {
  const allElements = props.commitmentBurndown.series_data.map(seriesElement);
  // we want to always show full 3 years months of burndown even if there is no data
  return allElements.map(el => {
    if (el.data.length <= DAYS_TO_SHOW) {
      const padding = new Array(DAYS_TO_SHOW - el.data.length).fill([0, 0]);
      const lastTimestamp = el.data[el.data.length - 1][0];

      for (let i = 0; i < DAYS_TO_SHOW - el.data.length; i++) {
        const nextTimestamp = lastTimestamp + (i + 1) * MS_IN_DAY;
        padding[i][0] = nextTimestamp;
      }
      el.data = el.data.concat(padding);
    }
    return el;
  });
});

const seriesElement = (series, index) => {
  const label = series.label;
  const msStart = +new Date(props.dataThroughDate);
  const enrich = (el, i) => [msStart + i * MS_IN_DAY, el];
  return {
    name: label === 'other' ? 'Others' : label,
    type: 'area',
    stacking: 'normal',
    data: series.data.map(enrich),
    color: getColor(label, index),
    marker: {
      enabled: false,
    },
    tooltip: {
      valuePrefix: '$',
    },
  };
};

const filteredSeriesElements = computed(() => {
  const selected = _.filter(legend.value, 'selected');
  const labels = _.map(selected, 'label');
  const isLabelSelected = s => _.includes(labels, s.name);
  return _.filter(seriesElements.value, isLabelSelected);
});

const defaultLegend = () => {
  var legendElements = seriesElements.value.map(e => {
    return {
      label: e.name,
      color: e.color,
      selected: true,
      hasData: true,
    };
  });

  return legendElements;
};
const legend = ref(defaultLegend());
</script>

<template>
  <div>
    <ChartLegend v-model:model-value="legend" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :tooltip="tooltip" :series="filteredSeriesElements" zoomable />
  </div>
</template>
