<script setup lang="ts">
import type {
  CommitmentLockInRiskDetailSeries,
  CurrentCommitmentLockInRiskSeries,
} from '@console/services/gcp/api.models';
import type { PointOptionsObject, SeriesOptionsType, XAxisOptions, YAxisOptions } from 'highcharts';

import { computed } from 'vue';

import { getColor } from './commitment_utility';
import { getMonthYAxis } from '@console/components/charts/utility';
import { isDefined } from '@shared/utilities/is_defined';

import BarChart from '@console/components/charts/BarChart.vue';

type Props = {
  data: (CurrentCommitmentLockInRiskSeries | CommitmentLockInRiskDetailSeries)[];
  max?: number | null;
};

const props = defineProps<Props>();

const enrichedData = computed<PointOptionsObject[]>(() => {
  return props.data.map((d, index) => ({
    ...d,
    y: (d as CurrentCommitmentLockInRiskSeries).value,
    color: getColor(d.label, index),
  }));
});

const series = computed<SeriesOptionsType[]>(() => {
  return [
    {
      type: 'bar',
      name: '',
      data: enrichedData.value,
    },
  ];
});

const xAxis = computed<XAxisOptions>(() => ({
  categories: props.data.map(d => d.label),
  labels: {
    // Custom formatter to wrap on ':'
    formatter: function (ctx) {
      var stringValue = ctx.value.toString();

      // render on 1 line if we have the space
      if (ctx.chart.chartWidth > 700) {
        return stringValue;
      }

      const parts = stringValue.split(':');
      if (parts.length === 1) return stringValue;

      let firstLine = '';
      let secondLine = '';
      while (parts.length) {
        if (firstLine.length < secondLine.length) {
          firstLine += parts.shift() + ':';
        } else {
          secondLine = ':' + parts.pop() + secondLine;
        }
      }
      if (secondLine.startsWith(':')) {
        secondLine = secondLine.slice(1);
      }

      return `<tspan>${firstLine}<br>${secondLine}</tspan>`;
    },
  },
}));

const yAxis = computed<YAxisOptions[]>(() => {
  var maxMonths = Math.max(...enrichedData.value.map(d => d.y).filter(isDefined));
  return [
    {
      max: props.max,
      ...getMonthYAxis(maxMonths),
      title: {
        text: null,
      },
    },
  ];
});
</script>

<template>
  <div>
    <BarChart :x-axis="xAxis" :y-axis="yAxis" :series="series" data-label-format="{y:.1f} months" />
  </div>
</template>
