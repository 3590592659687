<script>
export default {};
</script>

<template>
  <div class="setting">
    <div>
      <span class="label">
        <slot name="label" />
      </span>
    </div>
    <div>
      <slot name="value" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.setting {
  display: grid;
  grid-template-columns: 160px 1fr;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  > div {
    width: 100%;
    padding-right: 1rem;
    margin-top: 1rem;
  }
}

.label {
  font-weight: 400;
}

@include media-breakpoint-down(lg) {
  .setting {
    display: flex;
    flex-direction: column;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    > div {
      width: 100%;
      padding-right: 1rem;
      margin-top: 1rem;
    }
  }
}
</style>
