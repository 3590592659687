import type { PermissionLevel } from '@shared/state/auth.store';
import type { FeatureFlag } from '@shared/state/feature.store';
import type { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import _ from 'lodash';

import { getVuexStore } from '@console/state/vuex/store';
import { useAuthStore } from '@shared/state/auth.store';
import { useFeatureStore } from '@shared/state/feature.store';
import env from '@shared/utilities/environment';

const skipRequirements = () => {
  if (!env.isLocal()) return false;

  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.has('skip_route_requirements');
};

// We have a special navigation guard function that can process multiple requirements
type NavigationGuardRequirement = (to: RouteLocationNormalized, from: RouteLocationNormalized) => boolean;

const requires = (...requirements: NavigationGuardRequirement[]) => (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const results = _.map(requirements, requirement => requirement(to, from));
  const pass = _.every(results);
  if (skipRequirements()) {
    console.warn('Skipping requirements for local development. Result of requirements check:', pass);
    return next();
  }
  return pass ? next() : next({ name: 'root' });
};

const or = (...requirements: NavigationGuardRequirement[]) => (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  return requirements.some(req => req(to, from));
};

const isCustomerSettingEnabled = (setting: string) => () => {
  const store = getVuexStore();
  return store.getters['customer/isSettingEnabled'](setting);
};
const isFeatureEnabled = (flag: FeatureFlag) => () => {
  // https://pinia.vuejs.org/core-concepts/outside-component-usage.html
  // get store inside the function to ensure app.use(pinia) is called first
  const featureStore = useFeatureStore();
  return featureStore.featureFlags[flag];
};
const atLeastRole = (role: PermissionLevel) => () => {
  const store = getVuexStore();
  const authStore = useAuthStore();
  return authStore.isAtLeastRole(store.state.customer.selectedCompanyId, role);
};

const userIsEmployee = () => useAuthStore().isEmployee;
const selectedCompany = () => {
  const store = getVuexStore();
  return !!store.state.customer.selectedCompanyId;
};
const hasRoles = () => useAuthStore().hasRoles;
const activeSubscription = (to: RouteLocationNormalized) => {
  const store = getVuexStore();
  const isSubscribed = !!store.getters['customer/isSubscribed'];
  return userIsEmployee() ? isSubscribed || to.query.admin_override === 'true' : isSubscribed;
};

const notInDemo = () => {
  const store = getVuexStore();
  return !store.getters['customer/isDemo'];
};

const billingAccountOnboarded = () => {
  const store = getVuexStore();
  const id = store.state.nav?.context?.id;
  if (!id) {
    return false;
  }
  return store.getters['gcp/isBillingAccountOnboarded'](id);
};

export {
  requires,
  notInDemo,
  isCustomerSettingEnabled,
  isFeatureEnabled,
  atLeastRole,
  selectedCompany,
  hasRoles,
  activeSubscription,
  billingAccountOnboarded,
  userIsEmployee,
  or,
};
