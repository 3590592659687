<!--
  Bootstrap-styled modal with behavior from Headless UI
  Docs: https://headlessui.com/v1/vue/dialog
-->
<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import { ref, nextTick, watch } from 'vue';

type Props = {
  modelValue: boolean;
  size: string;
  title: string;
};

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue', 'shown']);
const closeModal = () => emit('update:modelValue', false);

const slotRef = ref(null);

watch(
  () => props.modelValue,
  value => {
    if (value) {
      // Wait for slot to render before emitting so that child elements will be available for listeners
      nextTick(() => {
        emit('shown');
      });
    }
  }
);
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <!-- TODO: Add transition support, documentation here: https://headlessui.com/v1/vue/dialog#transitions did not seems to work -->
  <!-- Set the initial focus to the slotRef (the modal body) to prevent the close button from being focused -->
  <Dialog :open="modelValue" :show="modelValue" :initial-focus="slotRef" @close="closeModal">
    <div class="modal-backdrop fade" :class="{ show: modelValue }" />
    <div class="modal fade" :class="{ show: modelValue }">
      <div class="modal-dialog modal-dialog-centered" :class="`modal-${size}`">
        <DialogPanel as="div" class="modal-content border-0">
          <div class="modal-header header border-0">
            <DialogTitle as="h5" class="modal-title">{{ title }}</DialogTitle>
            <button class="close" type="button" aria-label="Close" @click="closeModal">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" height="24" width="24">
                <path
                  d="m253.897 826.205-24.102-24.102L455.897 576 229.795 349.897l24.102-24.102L480 551.897l226.103-226.102 24.102 24.102L504.103 576l226.102 226.103-24.102 24.102L480 600.103 253.897 826.205Z"
                />
              </svg>
            </button>
          </div>
          <div ref="slotRef" class="modal-body">
            <slot />
          </div>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>

<style lang="scss" scoped>
@use '@shared/scss/colors.scss';

.show {
  display: block;
}

.header {
  line-height: 1.2;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 5px;
    content: ' ';
    background-image: linear-gradient(
      to right,
      map-get(colors.$theme-colors, 'primary'),
      map-get(colors.$theme-colors, 'success')
    );
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
}
</style>
