import type { NonComputeService } from '@console/services/api.models';

import { getVuexStore } from '@console/state/vuex/store';

const notNewOrganization = () => {
  const store = getVuexStore();
  return !store.getters['aws/isNewOrganization'];
};

const noActiveSubscriptionOrNotConfiguredOrg = () => {
  const store = getVuexStore();
  const noActiveSubscription = !store.getters['customer/isSubscribed'];
  const organizationNotConfigured = !store.getters['aws/selectedOrganizationIsConfigured'];
  return noActiveSubscription || organizationNotConfigured;
};
const hasSingleOrgSelected = () => {
  const store = getVuexStore();
  return !store.getters['aws/isMultiOrgSummarySelected'];
}

const isNonComputeEnabled = (service: NonComputeService) => () => {
  const store = getVuexStore();
  const services = store.getters['aws/selectedOrganizationEnabledNonComputeServices'];
  return services[service];
};

export {
  notNewOrganization,
  noActiveSubscriptionOrNotConfiguredOrg,
  hasSingleOrgSelected,
  isNonComputeEnabled,
}
