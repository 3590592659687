import type { NonComputeService } from '@console/services/api.models';
import type { RouteRecordRaw } from 'vue-router';

import { ArmRoutes } from './arm_routes';
import {
  hasSingleOrgSelected,
  isNonComputeEnabled,
  notNewOrganization,
  noActiveSubscriptionOrNotConfiguredOrg,
} from './requirements';
import {
  requires,
  hasRoles,
  selectedCompany,
  atLeastRole,
  activeSubscription,
  notInDemo,
  isFeatureEnabled,
} from '@console/router/requirements';
import { PermissionLevel } from '@shared/state/auth.store';

import Account from '@aws/views/Account.vue';
import Accounts from '@aws/views/Accounts.vue';
import ComputeCommitments from '@aws/views/compute/Commitments.vue';
import ComputePrepayments from '@aws/views/compute/Prepayments.vue';
import ComputeSavings from '@aws/views/compute/Savings.vue';
import ComputeSettings from '@aws/views/compute/Settings.vue';
import NonComputeCommitments from '@aws/views/non-compute/Commitments.vue';
import RequestSavingsAnalysis from '@aws/views/non-compute/RequestSavingsAnalysis.vue';
import NonComputeSavings from '@aws/views/non-compute/Savings.vue';
import NonComputeSettings from '@aws/views/non-compute/settings/Settings.vue';
import Onboarding from '@aws/views/Onboarding.vue';
import Settings from '@aws/views/Settings.vue';
import Showback from '@aws/views/Showback.vue';

const nonComputeServices: { service: NonComputeService; path: string }[] = [
  { service: 'elasti_cache', path: 'elasticache' },
  { service: 'memory_db', path: 'memorydb' },
  { service: 'open_search', path: 'opensearch' },
  { service: 'rds', path: 'rds' },
  { service: 'redshift', path: 'redshift' },
];

export const routes: RouteRecordRaw[] = [
  {
    path: '/aws/onboarding',
    name: 'aws_onboarding',
    component: Onboarding,
    beforeEnter: requires(
      notInDemo,
      hasRoles,
      selectedCompany,
      atLeastRole(PermissionLevel.Viewer),
      noActiveSubscriptionOrNotConfiguredOrg
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/showback/:timeframe?',
    name: 'aws_showback',
    component: Showback,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/accounts',
    name: 'aws_accounts',
    component: Accounts,
    beforeEnter: requires(
      notInDemo,
      hasRoles,
      selectedCompany,
      activeSubscription,
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/settings',
    name: 'aws_settings',
    component: Settings,
    beforeEnter: requires(
      isFeatureEnabled('customerSettings'),
      notInDemo,
      hasRoles,
      selectedCompany,
      atLeastRole(PermissionLevel.Editor),
      activeSubscription,
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/accounts/:awsAccountId',
    name: 'aws_configure_account',
    component: Account,
    beforeEnter: requires(
      notInDemo,
      hasRoles,
      selectedCompany,
      atLeastRole(PermissionLevel.Editor),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },

  // Compute Offering
  {
    path: '/aws/compute/savings/:timeframe?',
    name: 'aws_compute_savings',
    component: ComputeSavings,
    beforeEnter: requires(hasRoles, selectedCompany, atLeastRole(PermissionLevel.Viewer), notNewOrganization),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/compute/commitments',
    name: 'aws_compute_commitments',
    component: ComputeCommitments,
    props: true,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/compute/prepayments/:timeframe?',
    name: 'aws_compute_prepayments',
    component: ComputePrepayments,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/compute/settings',
    name: 'aws_compute_settings',
    component: ComputeSettings,
    props: true,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      activeSubscription,
      atLeastRole(PermissionLevel.Viewer),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },

  // AWS Non-Compute Offerings
  // Add in the pages that are common across all of the non-compute services
  ...nonComputeServices.flatMap(
    s =>
      [
        {
          path: `/aws/${s.path}/savings/:timeframe?`,
          name: `aws_${s.service}_savings_dashboard`,
          beforeEnter: requires(
            hasRoles,
            selectedCompany,
            activeSubscription,
            atLeastRole(PermissionLevel.Viewer),
            notNewOrganization,
            hasSingleOrgSelected,
            isNonComputeEnabled(s.service)
          ),
          component: NonComputeSavings,
          props: route => ({ service: s.service, ...route.params }),
          meta: {
            initModules: true,
          },
        },
        {
          path: `/aws/${s.path}/commitments`,
          name: `aws_${s.service}_commitments_dashboard`,
          beforeEnter: requires(
            hasRoles,
            selectedCompany,
            activeSubscription,
            atLeastRole(PermissionLevel.Viewer),
            notNewOrganization,
            hasSingleOrgSelected,
            isNonComputeEnabled(s.service)
          ),
          component: NonComputeCommitments,
          props: route => ({ service: s.service, ...route.params }),
          meta: {
            initModules: true,
          },
        },
        {
          path: `/aws/${s.path}/settings`,
          name: `aws_${s.service}_settings`,
          beforeEnter: requires(
            hasRoles,
            selectedCompany,
            activeSubscription,
            atLeastRole(PermissionLevel.Viewer),
            notNewOrganization,
            hasSingleOrgSelected,
            isNonComputeEnabled(s.service)
          ),
          component: NonComputeSettings,
          props: route => ({ service: s.service, ...route.params }),
          meta: {
            initModules: true,
          },
        },
        {
          path: `/aws/${s.path}`,
          name: `aws_${s.service}_request_savings_analysis`,
          beforeEnter: requires(
            hasRoles,
            selectedCompany,
            activeSubscription,
            atLeastRole(PermissionLevel.Viewer),
            notNewOrganization,
            hasSingleOrgSelected
          ),
          component: RequestSavingsAnalysis,
          props: route => ({ service: s.service, ...route.params }),
          meta: {
            initModules: true,
          },
        },

        // Temporarily keep this redirect, in case a user has this URL or opens in a new tab
        {
          path: `/aws/${s.path}/express_interest`,
          redirect: { name: `aws_${s.service}_request_savings_analysis` },
        },
        {
          path: `/aws/${s.path}/request_savings_analysis`,
          redirect: { name: `aws_${s.service}_request_savings_analysis` },
        },
      ] as RouteRecordRaw[]
  ),

  // Add routes for other aws features here
  ...ArmRoutes,
];
