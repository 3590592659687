<script setup>
import { PermissionSet, validateBillingAccountAccess } from '@gcp/services/accessValidation';

import ValidationStepperItem from './ValidationStepperItem.vue';
import CopyButton from '@shared/design/CopyButtonV2.vue';

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  billingAccountId: {
    type: String,
    required: true,
  },
  serviceAccount: {
    type: Object,
    required: true,
  },
  role: {
    type: Object,
    required: true,
  },
  permissions: {
    type: String,
    required: true,
  },
  onValidated: {
    type: Function,
    required: true,
  },
  // Only used when permissions = "Management"
  includeAnalysisPermissions: {
    type: Boolean,
    default: false,
  },
});

const billingAccountUrl = `https://console.cloud.google.com/billing/${props.billingAccountId}/manage`;

const validate = async () => {
  // Always validate SA level permissions
  await validateBillingAccountAccess(props.billingAccountId, PermissionSet.SavingsAnalysis);
  if (props.permissions === PermissionSet.Management) {
    await validateBillingAccountAccess(props.billingAccountId, PermissionSet.Management);
  }
  await props.onValidated();
};
</script>

<template>
  <ValidationStepperItem title="Billing Account Access" :open="props.open" :validate="validate">
    <template #default>
      <ul class="pl-3">
        <li>
          Navigate to your
          <a target="_blank" rel="noopener noreferrer" :href="billingAccountUrl">Google Cloud Billing Account</a>
        </li>
        <li>
          Click <b>Add Principal</b> and add the following new principal:<br />
          <span class="text-monospace bg-light p-1">{{ props.serviceAccount?.email }}</span>
          <CopyButton :key="props.serviceAccount?.email" class="ml-2" :text="props.serviceAccount?.email" />
        </li>
        <li v-if="permissions === PermissionSet.SavingsAnalysis">Assign the <b>Billing Account Viewer </b>role</li>
        <li v-else-if="includeAnalysisPermissions">
          Assign the <b>Billing Account Viewer</b> and <b>Consumer Procurement Order Administrator</b> roles
        </li>
        <li v-else>Assign the <b>Consumer Procurement Order Administrator</b> role</li>
      </ul>
    </template>
    <template #error>
      Hmm, we weren't able to validate access. Please ensure the principal name is correct and the specified roles are
      assigned. Also confirm that the <b>{{ props.role.title }}</b> role has the permissions shown above. If the issue
      persists, please chat with us for help.
    </template>
  </ValidationStepperItem>
</template>
