<script setup lang="ts">
import type {
  AwsAccount,
  AwsOrganizationResponse,
  AwsService,
  ComputeSavingsDashboardResponse,
  NonComputeSavingsDashboardResponse,
} from '@console/services/api.models';

import moment from 'moment';
import qs from 'qs';
import { computed } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import env from '@shared/utilities/environment';
import { dateFormat, dateUtc } from '@shared/utilities/filters';
import NumberHelpers from '@shared/utilities/number_helpers';

import Currency from '@shared/components/Currency.vue';
import InfoTooltip from '@shared/design/InfoTooltip.vue';
import Modal from '@shared/design/Modal.vue';

type AwsQueryStringFilter = {
  dimension: string;
  values: string[];
  include: boolean;
  children: null;
};

type Props = {
  savings: ComputeSavingsDashboardResponse | NonComputeSavingsDashboardResponse;
  organization: AwsOrganizationResponse;
  awsAccounts: AwsAccount[];
  demo?: boolean;
  includeTotals?: boolean;
  service?: AwsService;
};

const props = withDefaults(defineProps<Props>(), {
  demo: false,
  includeTotals: true,
  service: 'compute',
});

const vuexStore = useVuexStore();
const isMultiOrgSummarySelected = computed(() => vuexStore.getters['aws/isMultiOrgSummarySelected']);
const isReseller = computed(() => vuexStore.getters['customer/isReseller']);

const isFinalized = computed(() => props.savings.dashboard.is_finalized);
const dataThroughDate = computed(() => props.savings.dashboard.data_through_date);
const totalSavings = computed(() => props.savings.dashboard.savings_summary.total_savings);
const formattedSavings = computed(() => NumberHelpers.formatDollars(Math.abs(totalSavings.value)));

const computeDashboard = computed(() => {
  if ('ec2_cyclicality_detected' in props.savings.dashboard) {
    return props.savings.dashboard;
  }
  return null;
});

const nonComputeDashboard = computed(() => {
  if (!('ec2_cyclicality_detected' in props.savings.dashboard)) {
    return props.savings.dashboard;
  }
  return null;
});

const savingsPlanPrivateDiscountSavings = computed(
  () => computeDashboard.value?.savings_summary?.savings_plan_private_discount_savings ?? 0
);

const showPrivateRateDiscount = computed(() => {
  return savingsPlanPrivateDiscountSavings.value > 0;
});

const savingsPlanSavings = computed(
  // Add private savings so the value we show matches cost explorer
  () => (computeDashboard.value?.savings_summary?.savings_plan_savings ?? 0) + savingsPlanPrivateDiscountSavings.value
);

const reservedInstanceSavings = computed(() => computeDashboard.value?.savings_summary?.ec2_reserved_instance_savings);
const nonComputeReservationSavings = computed(() => nonComputeDashboard.value?.savings_summary?.reservation_savings);

const startDate = computed(() => moment.utc(props.savings.month_start).format('YYYY-MM-DD'));
const endDate = computed(() =>
  moment.utc(props.savings.dashboard.data_through_date).add(1, 'days').format('YYYY-MM-DD')
);
const awsAccountNumber = computed(() =>
  props.demo ? 'xxxx xxxx xxxx' : props.organization.management_aws_account_number
);

const reservedInstanceLink = computed(() => {
  if (props.demo) {
    return '/';
  }
  const filters = isReseller.value ? filterByAwsAccounts(props.awsAccounts) : [];
  const queryString = riQueryString(filters);
  return `https://console.aws.amazon.com/cost-management/home?region=us-east-1#/ri/utilization?${queryString}`;
});

const savingsPlanLink = computed(() => {
  if (props.demo) {
    return '/';
  }
  const filters = isReseller.value ? filterByAwsAccounts(props.awsAccounts) : [];
  const queryString = spQueryString(filters);
  return `https://console.aws.amazon.com/cost-management/home?region=us-east-1#/savings-plans/utilization?${queryString}`;
});

const nonComputeCostExplorerLink = computed(() => {
  if (props.demo) {
    return '/';
  }
  const queryString = riQueryString([]);
  return `https://console.aws.amazon.com/cost-management/home?region=us-east-1#/ri/utilization?${queryString}`;
});

const serviceDisplayName = computed(() => AwsServiceHelpers.getDisplayName(props.service));
const isProductCompute = computed(() => props.service === 'compute');
const riCostExplorerServiceName = computed(() => {
  // Use the EC2 service name for Compute, since this is for RI utilization which doesn't apply to the other
  // Compute services (i.e. Lambda & Fargate)
  const service = props.service === 'compute' ? 'ec2' : props.service;

  return AwsServiceHelpers.getCostExplorerReservedInstanceServiceName(service);
});

const hasSavingsPlan = computed(() => !!computeDashboard.value?.key_metrics?.savings_plan_utilization_percentage);

const serviceSpendLabel = () => {
  var label = `${serviceDisplayName.value} spend`;

  if (isProductCompute.value) {
    if (isReseller.value) {
      return `arbitrageable ${label}`;
    }
  }

  return label;
};

const multiOrgText = () => {
  return isMultiOrgSummarySelected.value ? ' across all Organizations' : '';
};

const filterByAwsAccounts = (awsAccounts: AwsAccount[]) => {
  return [
    {
      dimension: 'LinkedAccount',
      values: awsAccounts.map(a => a.aws_account_number),
      include: true,
      children: null,
    },
  ];
};

const riQueryString = (extraFilters: AwsQueryStringFilter[] = []) => {
  return qs.stringify({
    groupBy: 'None',
    chartStyle: 'Line',
    timeRangeOption: 'Custom',
    granularity: 'Daily',
    reportName: 'RI Utilization',
    usageAs: 'usageQuantity',
    subscriptionIds: JSON.stringify([]),
    filter: JSON.stringify([
      ...extraFilters,
      {
        dimension: 'Service',
        values: [riCostExplorerServiceName.value],
        include: true,
        children: null,
      },
    ]),
    reportId: '',
    target: 100,
    startDate: startDate.value,
    endDate: endDate.value,
  });
};

const spQueryString = (extraFilters: AwsQueryStringFilter[] = []) => {
  return qs.stringify({
    subscriptionIds: JSON.stringify([]),
    chartStyle: 'Line',
    timeRangeOption: 'Custom',
    granularity: 'Daily',
    reportName: 'Utilization report',
    reportType: 'SavingsPlansUtilization',
    isTemplate: true,
    filter: JSON.stringify([...extraFilters]),
    startDate: startDate.value,
    endDate: endDate.value,
  });
};

const formatDate = (date: string, format = 'MMMM') => {
  return dateFormat(dateUtc(date), format);
};
</script>

<template>
  <div class="pl-2 pt-1 pb-1">
    <Modal id="cost-explorer" size="lg" title="AWS Cost Explorer">
      <p>
        ProsperOps considers AWS systems authoritative for savings data. If you&apos;d like to validate our numbers with
        AWS, open a new browser tab and login to the AWS Console on your
        <strong>management account ({{ awsAccountNumber }})</strong>
        with sufficient permissions to view AWS Cost Explorer, then click
        <span v-if="hasSavingsPlan">each</span><span v-else>the</span>
        button below.
      </p>
      <table class="table costExplorerBreakdown mt-4">
        <tr v-if="reservedInstanceSavings" class="subtotal">
          <td>
            <b-button
              target="_blank"
              :href="reservedInstanceLink"
              variant="primary"
              :disabled="demo"
              class="rounded-sm"
            >
              View Reserved Instance Savings
              <BaseIcon class="ml-2" name="external-link-alt" />
            </b-button>
          </td>
          <td>
            <BaseIcon name="arrow-right" />
          </td>
          <td>
            <div class="highlight">
              <Currency :value="reservedInstanceSavings" />
            </div>
            <strong>(Total Net Savings)</strong>
          </td>
        </tr>
        <tr v-if="hasSavingsPlan" class="subtotal">
          <td :class="showPrivateRateDiscount && 'pb-0'">
            <b-button target="_blank" :href="savingsPlanLink" variant="primary" :disabled="demo" class="rounded-sm">
              View Savings Plan Savings
              <BaseIcon class="ml-2" name="external-link-alt" />
            </b-button>
          </td>
          <td :class="showPrivateRateDiscount && 'pb-0'">
            <BaseIcon name="arrow-right" />
          </td>
          <td :class="showPrivateRateDiscount && 'pb-0'">
            <div class="highlight">
              <Currency :value="savingsPlanSavings" data-test-id="sp-savings" />
            </div>
            <strong>(Total Net Savings vs On-Demand)</strong>
          </td>
        </tr>
        <tr v-if="showPrivateRateDiscount" class="subtotal">
          <td class="pt-2" />
          <td class="pt-2" />
          <td class="pt-2">
            <div class="highlight negative">
              <Currency :value="savingsPlanPrivateDiscountSavings * -1" signed data-test-id="sp-bundled-savings" />
            </div>
            <strong class="ml-4">(Bundled Private Savings)</strong>
            <InfoTooltip>
              Savings Plans in your AWS organization have AWS private discounts (e.g. EDP, PPA) bundled with native
              Savings Plan discounts. AWS Cost Explorer shows bundled savings, whereas our console (and savings share)
              reflects native Reserved Instance and Savings Plan savings only. A Bundled Private Savings adjustment is
              displayed here to reconcile this discrepancy.
              <!-- TODO: Waiting for link from product, only show in dev/local for now -->
              <a v-if="!env.isProduction()" href="#" target="_blank" rel="noopener noreferrer" class="text-nowrap">
                Learn more
              </a>
            </InfoTooltip>
          </td>
        </tr>
        <tr v-if="nonComputeReservationSavings" class="subtotal">
          <td>
            <b-button
              target="_blank"
              :href="nonComputeCostExplorerLink"
              variant="primary"
              :disabled="demo"
              class="rounded-sm"
            >
              View {{ serviceDisplayName }} Savings
              <BaseIcon class="ml-2" name="external-link-alt" />
            </b-button>
          </td>
          <td>
            <BaseIcon name="arrow-right" />
          </td>
          <td>
            <div class="highlight">
              <Currency :value="nonComputeReservationSavings" />
            </div>
            <strong>(Total Net Savings)</strong>
          </td>
        </tr>
        <tr v-if="includeTotals" class="total">
          <td>Total Savings</td>
          <td>
            <BaseIcon name="arrow-right" />
          </td>
          <td>
            <Currency :value="totalSavings" />
          </td>
        </tr>
      </table>
    </Modal>
    <span class="callout mr-2">
      <span v-if="isFinalized">
        In {{ formatDate(dataThroughDate) }}, you
        {{ totalSavings >= 0 ? 'saved' : 'lost' }}
      </span>
      <span v-else>
        So far in
        {{ formatDate(dataThroughDate) }}, you've
        {{ totalSavings >= 0 ? 'saved' : 'lost' }}
      </span>
      <span
        class="total"
        :class="{
          'text-success': totalSavings >= 0,
          'text-danger': totalSavings < 0,
        }"
        >&nbsp;{{ formattedSavings }}</span
      >
      on your {{ serviceSpendLabel() }}{{ multiOrgText() }}.
    </span>
    <small v-if="!isMultiOrgSummarySelected" class="text-nowrap">
      <a v-b-modal.cost-explorer href="#" @click.prevent>Show me this in AWS Cost Explorer</a>
    </small>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.callout {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1.2;
}

.total {
  font-weight: bold;
}

table.costExplorerBreakdown {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  table-layout: fixed;

  td {
    padding-bottom: 1rem;
    text-align: center;
    vertical-align: middle;
  }

  a {
    width: 100%;
    border-radius: 0;
  }

  tr.subtotal td:last-child {
    .highlight {
      font-size: 2rem;
      font-weight: bold;
      color: map-get($theme-colors, 'primary');

      &.negative {
        color: $gray-500;
      }
    }
  }

  tr.total td:first-child,
  tr.total td:last-child {
    font-size: 2rem;
    font-weight: bold;
    color: map-get($theme-colors, 'success');
  }

  tr td:nth-child(2) {
    font-size: 2rem;
  }

  tr:not(:last-child) {
    td {
      border-top: 0;
    }
  }

  td:nth-child(1) {
    width: 45%;
  }

  td:nth-child(2) {
    width: 10%;
  }

  td:nth-child(3) {
    width: 45%;
  }
}
</style>
