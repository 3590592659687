import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import * as sentry from '@console/lib/sentry';
/* eslint-disable */
// order is critical here
// store is created as a side-effect of importing this module
import { store } from '@console/state/vuex/create-store';
// router assumes store will be created, and can be retrieved via getVuexStore
import router from '@console/router';
/* eslint-enable */
import { injectionKey } from '@console/state/vuex/store';
import { useSharedDesign } from '@shared/design/install';
import { useHighcharts } from '@shared/lib/highcharts';
import '@shared/middleware';
import '@shared/utilities/filters';

import App from '@console/App.vue';

const unhead = createHead();
const pinia = createPinia();
const app = createApp({
  ...App,
});

sentry.init({ app, router, store });

useSharedDesign(app);
useHighcharts(app);

app.use(store, injectionKey);
app.use(router);
app.use(unhead);
app.use(pinia);
app.mount('#app');
