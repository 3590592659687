<script setup lang="ts">
import { useHead } from '@unhead/vue';

import DataExport from '@aws/components/settings/data-export/DataExport.vue';
import Layout from '@console/Layout.vue';
import PageHeader from '@shared/design/PageHeader.vue';

useHead({
  title: 'AWS Organization Settings',
});
</script>

<template>
  <Layout :loading="false" with-footer>
    <template #default>
      <div>
        <PageHeader>
          <h1>AWS Organization Settings</h1>
        </PageHeader>
        <div class="row sectional">
          <div class="col">
            <DataExport />
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>
