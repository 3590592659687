import type { Ec2InventoryItem } from '@console/services/aws/arm/api.models';
import type { AwsOrganizationId } from '@shared/services/namedIds';

import { core } from '@shared/services/api';

async function getEc2Inventory(awsOrganizationId: AwsOrganizationId): Promise<Array<Ec2InventoryItem>> {
  const res = await core.get<Array<Ec2InventoryItem>>(`/v1/aws/organizations/${awsOrganizationId}/arm/ec2/inventory`);
  return res.data;
}

export { getEc2Inventory };
