<script>
import { mapGetters } from 'vuex';

import { PusherSetup } from '@console/mixins/pusher_setup';

import AwsAccountFilterList from '@aws/components/accounts/AwsAccountFilterList.vue';
import Layout from '@console/Layout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';

export default {
  components: {
    Layout,
    PageHeader,
    AwsAccountFilterList,
    BoxMessage,
  },
  mixins: [PusherSetup],
  head: {
    title: 'AWS Accounts',
  },
  computed: {
    ...mapGetters('aws', [
      'selectedOrganizationManagementAccount',
      'selectedOrganizationChildAccounts',
      'selectedOrganizationIsPaused',
    ]),
    awsAccounts() {
      // If the management account isn't defined yet, it means the organization hasn't been loaded yet. Just return
      // an empty array for the accounts until they are populated
      if (!this.selectedOrganizationManagementAccount) return [];

      const mgmt = {
        ...this.selectedOrganizationManagementAccount,
        is_management_account: true,
      };
      const linked = this.selectedOrganizationChildAccounts;
      return [mgmt, ...linked];
    },
  },
};
</script>

<template>
  <Layout>
    <template #default>
      <PageHeader>
        <h1>AWS Accounts</h1>
      </PageHeader>
      <BoxMessage v-if="selectedOrganizationIsPaused" type="warning" class="pt-2 pb-4">
        <strong>You have accounts that need your attention!</strong>
        <p>
          Real-time discount management requires we have visibility into all member accounts in your Organization.
          Without the IAM policy that gives us this visibility, suboptimal savings outcomes may result.
        </p>
        <p class="mb-0">
          To automate creation of the ProsperOps role across all AWS accounts in your Organization, please see
          <a href="https://help.prosperops.com/automating-rollout-of-the-prosperops-iam-role" target="_blank"
            >this help article</a
          >.
        </p>
      </BoxMessage>
      <div class="pt-2">
        <AwsAccountFilterList :aws-accounts="awsAccounts" paginate />
      </div>
    </template>
  </Layout>
</template>
