<script setup lang="ts" generic="TOption extends string">
import type { PanelDropdownChangeEvent } from './types';
import type { Ref } from 'vue';

import { computed, ref } from 'vue';

type Props = {
  name: string;
  options: ReadonlyArray<TOption>;
  /**
   * If not provided, state will be managed internally and 'change' or 'update:modelValue' events can be used to listen for changes
   */
  modelValue?: TOption;
};

const props = defineProps<Props>();

const internalModel = ref<TOption>(props.options[0]) as Ref<TOption>;
const selected = computed<TOption>({
  get: () => (props.modelValue !== undefined ? props.modelValue : internalModel.value),
  set: (value: TOption) => {
    internalModel.value = value;
    emit('update:modelValue', value);
  },
});

const emit = defineEmits<{
  (event: 'change', payload: PanelDropdownChangeEvent<TOption>): void;
  (event: 'update:modelValue', payload: TOption): void;
}>();

const change = (newSelected: TOption) => {
  selected.value = newSelected;
  const event = { name: props.name, selected: newSelected };
  emit('change', event);
};
</script>

<script lang="ts">
export default {
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <b-dropdown
    variant="transparent"
    menu-class="rounded-0"
    toggle-class="btn-no-focus-box-shadow rounded-sm pt-0"
    size="sm"
    right
    no-caret
  >
    <template #button-content>
      <div v-if="selected" class="d-flex align-items-center justify-content-center">
        <div class="text-capitalize">
          {{ selected }}
        </div>
        <div class="ml-1">
          <BaseIcon name="angle-down" />
        </div>
      </div>
    </template>
    <b-dropdown-item v-for="option in options" :key="option" class="text-capitalize" @click.prevent="change(option)">
      <div class="d-flex flex-column align-items-end justify-content-center">{{ option }}</div>
    </b-dropdown-item>
  </b-dropdown>
</template>
