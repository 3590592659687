<script setup lang="ts">
import type { YAxisOptions, YAxisPlotBandsOptions } from 'highcharts';

import { computed, ref } from 'vue';

import { getGradientBreakpoints } from './commitment_utility';

import NeedleGaugeChart from '@console/components/charts/NeedleGaugeChart.vue';
import Modal from '@shared/design/ModalV2.vue';

const props = defineProps<{
  value: number;
  baseline?: number | null;
  variant: 'laddered' | 'term-optimized';
}>();

const showPreviousClr = ref(false);

// Theme colors seemed too harsh
const colors = {
  green: '#00d597', // success lightened 5%
  yellow: '#fcc137', // warning lightened 5%
  red: '#fc6262', // danger lightened 8%
};

const min = computed(() => (props.variant === 'term-optimized' ? 0 : 3));
const max = computed(() => (props.variant === 'term-optimized' ? 18 : 12));

// The angle that the gradient is applied needs to be adjusted based on the scale
const plotBands = computed<Array<YAxisPlotBandsOptions>>(() => {
  const breakpoints = getGradientBreakpoints(min.value, max.value);
  return [
    {
      thickness: 20,
      from: min.value,
      to: breakpoints.yellow + breakpoints.radiusOffset,
      zIndex: 0,
      borderRadius: '50%',
      color: colors.green,
    },
    {
      thickness: 20,
      from: breakpoints.yellow - breakpoints.gradientOffset,
      to: breakpoints.yellow + breakpoints.gradientOffset,
      zIndex: 2,
      color: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 0.1,
        },
        stops: [
          [0.2, colors.green],
          [0.8, colors.yellow],
        ],
      },
    },
    {
      thickness: 20,
      from: breakpoints.yellow,
      to: breakpoints.red,
      zIndex: 1,
      color: colors.yellow,
    },
    {
      thickness: 20,
      from: breakpoints.red - breakpoints.gradientOffset,
      to: breakpoints.red + breakpoints.gradientOffset,
      zIndex: 2,
      color: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 1.2,
        },
        stops: [
          [0.2, colors.yellow],
          [0.8, colors.red],
        ],
      },
    },
    {
      thickness: 20,
      from: breakpoints.red,
      to: max.value,
      zIndex: 0,
      color: colors.red,
      borderRadius: '50%',
    },
  ];
});

const yAxis: YAxisOptions = {
  lineWidth: 0,
  minorTickInterval: 0,
  tickLength: 0,
  tickPositions: [min.value, max.value],
  labels: {
    distance: -10,
    y: 20,
    style: {
      fontSize: '15px',
    },
  },
  min: min.value,
  max: max.value,
  plotBands: plotBands.value,
};
</script>

<template>
  <div class="h-100">
    <div class="currentClr">
      <div>
        <NeedleGaugeChart title="Savings Rate" :value="value" :min="min" :max="max" :y-axis="yAxis" />
        <p>
          ProsperOps algorithms seek to minimize Commitment Lock-In Risk. This metric measures the maximum weighted
          average duration of your active commitment portfolio and reflects cloud provider lock-in.
          <a href="https://www.prosperops.com/blog/introducing-commitment-lock-in-risk" class="ml-2" target="_blank"
            >Learn More</a
          >
        </p>
        <div v-if="baseline" class="priorClr">
          <small class="text-center">
            <a href="#" class="text-muted" @click.prevent="showPreviousClr = !showPreviousClr">
              What was my Commitment Lock-In Risk before ProsperOps?
            </a>
          </small>
        </div>
        <Modal v-model="showPreviousClr" title="Commitment Lock-In Risk before ProsperOps" size="md">
          <p>Your Commitment Lock-In Risk at the time ProsperOps management began was:</p>
          <div class="pb-4 text-center display-4">
            <strong>{{ baseline }}</strong> <span style="font-size: 3.1rem">Months</span>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.currentClr {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;

    > p {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-bottom: 0;
    }
  }
}

.priorClr {
  padding: 1rem 0;
  margin: 2rem 3rem 0 3rem;
  text-align: center;
  border-top: 1px solid $gray-200;
}
</style>
