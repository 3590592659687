import { RoleTypes } from '@shared/services/customer.models';

export function getRoleDescriptions(showGranular: boolean, isAzureEnabled: boolean) {
  const options = [
    {
      name: RoleTypes.Viewer,
      text:
        'This role grants read-only access to the ProsperOps Console, with the exception of the User Management and Billing sections, which are not accessible.',
    },
    {
      name: RoleTypes.Editor,
      text:
        isAzureEnabled ?
          'This role grants Viewer permissions plus the ability to add new AWS Organizations, Azure Billing Scopes, and Google Cloud Billing Accounts, as well as configure cloud provider access. The User Management and Billing sections are not accessible.' :
          'This role grants Viewer permissions plus the ability to add new AWS Organizations and Google Cloud Billing Accounts, as well as configure cloud provider access. The User Management and Billing sections are not accessible.',

    },
    {
      name: RoleTypes.Owner,
      text:
        'This role grants full access to the ProsperOps Console, including the ability to view and manage users and billing.',
    },
  ];
  if (showGranular) {
    options.push({
      name: RoleTypes.Granular,
      text:
        isAzureEnabled ?
          'This option offers more granular control over which AWS Organizations, Azure Billing Scopes, and/or Google Cloud Billing Accounts this user has access to.' :
          'This option offers more granular control over which AWS Organizations and/or Google Cloud Billing Accounts this user has access to.',
    });
  }
  return options;
}
